import React from "react";
import { Box, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

const FormField = ({ label, children, required, ...props }) => {
  return (
    <Box className="w-full flex flex-col gap-1" {...props}>
      {/* Label */}
      <InputLabel className="text-[10px] text-[#505050]">
        {label} {required && <span> *</span>}
      </InputLabel>
      {/* Render children passed from outside */}
      {children}
    </Box>
  );
};

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
};

FormField.defaultProps = {
  required: false,
};


export default FormField;
