import ReportsDetailProvider from "./context/reportsDetailProvider";
import ReportDetailWithState from "./ReportDetailWithState";

const ReportDetail = () => {
  return (
    <ReportsDetailProvider>
      <ReportDetailWithState />
    </ReportsDetailProvider>
  );
};
export default ReportDetail;
