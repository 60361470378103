import React from "react";
import { TextField } from "@mui/material";
import { STRINGS } from "../utils/strings";

const Search = ({ currentSearchValue, setSearchState }) => {
  return (
    <div className="my-6">
      <TextField
        className="placeholder:text-center rounded-lg w-full text-sm"
        placeholder={STRINGS.SEARCH_PLACEHOLDER}
        size="small"
        name="report-detail-search"
        value={currentSearchValue}
        InputLabelProps={{ shrink: true }}
        label="Search"
        onChange={(event) => {
          let searchVal = event.target.value;
          searchVal = searchVal.length === 0 ? " " : searchVal;
          setSearchState(searchVal);
        }}
      />
    </div>
  );
};
export default Search;
