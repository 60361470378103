import React, { useContext, useEffect } from "react";
import Header from "./components/Header";
import { useDebouncedEffect } from "../../../../../../hooks/useDebounceEffect";
import {
  CONTENT_DOWNLOAD_ERROR,
  CONTENT_DOWNLOAD_SUCCESS,
  setFilterParamsList,
  setSearchDelayed,
  setSelectedProvider,
  setShouldFetchData,
  setShowContent,
} from "./state/actions";
import useGetReportDetail from "./hooks/useFetchReportDetail";
import DataContext from "../../../../../../../context/DataContext";
import {
  ReportsDetailDispatchContext,
  ReportsDetailStateContext,
} from "./context/reportsDetailProvider";
import ReportTable from "./components/ReportTable";
import { useNavigate, useParams } from "react-router-dom";
import ReportPagination from "./components/ReportPagination";
import { GenerateQueryParameterString } from "../../../../../../global/GenerateQueryParamString";
import { CircularProgress } from "@mui/material";
import ProviderDetailv2 from "../../../../../../global/ProviderDetailModal/ProviderDetailv2";
import ReportModal from "./components/ReportModal";
import { reports } from "../../../../../../api/endpoints/reports";

const ReportDetailWithState = () => {
  const state = useContext(ReportsDetailStateContext);
  const dispatch = useContext(ReportsDetailDispatchContext);
  const reportIdParams = useParams();
  const { accessToken } = useContext(DataContext);
  const navigate = useNavigate();

  const [fetchReportDetail] = useGetReportDetail(
    reportIdParams.id,
    state.filterString,
    accessToken,
    dispatch
  );

  useEffect(() => {
    let active = true;
    if (active) {
      let paramObj = {
        ...(state.delayedSearchString
          ? { search: state.delayedSearchString }
          : {}),
        ...(state.resLimit ? { limit: state.resLimit } : {}),
        ...(state.offset ? { offset: state.offset } : {}),
        ...(state.orderingBy
          ? {
              ordering:
                state.orderingDirection === "asc"
                  ? state.orderingBy
                  : "-" + state.orderingBy,
            }
          : {}),
      };
      let filtStr = GenerateQueryParameterString(paramObj);
      setFilterParamsList(dispatch, filtStr);
      setShouldFetchData(dispatch, true);
    }
    return () => {
      active = false;
    };
  }, [
    dispatch,
    state.delayedSearchString,
    state.resLimit,
    state.orderingBy,
    state.orderingDirection,
    state.offset,
  ]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (state.shouldFetchData) {
        fetchReportDetail(
          reportIdParams.id,
          state.filterString,
          accessToken,
          dispatch
        );
      }
    }
    return () => {
      active = false;
    };
  }, [
    reportIdParams,
    state.shouldFetchData,
    state.filterString,
    accessToken,
    dispatch,
    fetchReportDetail,
  ]);

  useDebouncedEffect(
    () => {
      if (state.search) {
        setSearchDelayed(dispatch, state.search);
      }
    },
    [state.search],
    800
  );

  useEffect(() => {
    let active = true;
    if (active) {
      if (state.errorArray.length > 0) {
        navigate("/reports");
      }
    }

    return () => {
      active = false;
    };
  }, [state.errorArray, navigate]);

  const closeProviderModal = () => {
    setSelectedProvider(dispatch, null);
  };

  const handleExport = () => {
    //remove limit from filterString
    let paramObj = {
      ...(state.delayedSearchString
        ? { search: state.delayedSearchString }
        : {}),
      ...(state.offset ? { offset: state.offset } : {}),
      ...(state.orderingBy
        ? {
            ordering:
              state.orderingDirection === "asc"
                ? state.orderingBy
                : "-" + state.orderingBy,
          }
        : {}),
    };
    let filtStr = GenerateQueryParameterString(paramObj);

    reports
      .exportFinancialReportDetail(
        reportIdParams.id,
        filtStr,
        accessToken
      )
      .then((res) => {
        setShowContent(dispatch, CONTENT_DOWNLOAD_SUCCESS);
        return res;
      })
      .catch((error) => {
        setShowContent(dispatch, CONTENT_DOWNLOAD_ERROR);
      });
  };

  return (
    <div id="Report-Detail" data-testid="Report">
      <div className="shadow-md rounded-md bg-white p-4">
        <Header />
        {!state.loading ? (
          <>
            {state.data && state.data.length > 0 && <ReportTable />}
            <ReportPagination />
          </>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </div>

      {state.selectedProvider && (
        <ProviderDetailv2
          providerDetail={state.selectedProvider}
          open={state.selectedProvider ? true : false}
          setOpen={closeProviderModal}
        />
      )}
      <ReportModal
        reportName={!state.loading && state.reportTitle}
        reportId={reportIdParams.id}
        handleExportReport={handleExport}
      />
    </div>
  );
};
export default ReportDetailWithState;
