import ApiServices from "../service";

export const reports = {
  getFinancialReportsList: async (
    orderingValue,
    pagination,
    searchValue,
    accessToken
  ) => {
    let search =
      searchValue && searchValue.trim() !== "" ? `${searchValue}` : "";
    let ordering =
      orderingValue && orderingValue !== "" ? `&ordering=${orderingValue}` : "";
    let paginate = pagination ? `&${pagination}` : "";

    return ApiServices.getData(
      `/api/tabular_reports/?${search}${paginate}${ordering}`,
      accessToken
    )
      .then((res) => res)
      .then((res) => res)
      .catch((err) => err);
  },

  getFinancialReportDetail: async (reportId, queryParams, accessToken) => {
    return ApiServices.getData(
      `/api/tabular_reports/${reportId}/run${queryParams}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 404) {
          throw new Error(res);
        }
        return res;
      })
      .then((res) => res)
      .catch((err) => err);
  },
  exportFinancialReportDetail: async (reportId, queryParams, accessToken) => {
    return ApiServices.getData(
      `/api/tabular_reports/${reportId}/export${queryParams}`,
      accessToken
    )
      .then((res) => {
        if (res.status === 404) {
          throw new Error(res);
        }
        return res;
      })
      .then((res) => res)
      .catch((err) => err);
  },
};
