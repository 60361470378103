import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";
import FormatCurrency from "../utils/FormatCurrency";

const CurrentBalance = ({ fieldName, value, flag, clock }) => {
  return (
    <BaseField
      id="SB-Current-Balance"
      testId="SB-Current-Balance"
      fieldName={fieldName}
      label={"Current Balance"}
      flag={flag}
      clock={clock}
    >
      <span className="font-bold">{FormatCurrency(ValueWithFallback(value))}</span>
    </BaseField>
  );
};

export default CurrentBalance;
