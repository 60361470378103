import React, { useContext } from "react";
import { Close } from "@mui/icons-material";
import { Button, CircularProgress, Dialog } from "@mui/material";
import {
  CONTENT_DOWNLOAD_CONFIRMATION,
  CONTENT_DOWNLOAD_ERROR,
  CONTENT_DOWNLOAD_IN_PROGRESS,
  CONTENT_DOWNLOAD_SUCCESS,
  setfetchError,
  setShowContent,
  toggleDialog,
} from "../state/actions";

import DownloadConfirmation from "./DownloadConfirmation";
import {
  ReportsDetailDispatchContext,
  ReportsDetailStateContext,
} from "../context/reportsDetailProvider";
import LoadingIndicator from "../../../../../../../global/LoadingIndicator";

const ReportModal = ({ reportName, reportId, handleExportReport }) => {
  const state = useContext(ReportsDetailStateContext);
  const dispatch = useContext(ReportsDetailDispatchContext);

  const setUpDialogContent = (content) => {
    return getDialogContentUI(content);
  };

  const isConfirmed = (confirmed) => {
    if (confirmed) {
      setShowContent(dispatch, CONTENT_DOWNLOAD_IN_PROGRESS);
      handleExportReport();
    }
  };

  const getDialogContentUI = (content) => {
    switch (content) {
      case CONTENT_DOWNLOAD_CONFIRMATION: {
        return (
          <DownloadConfirmation
            reportName={reportName}
            reportId={reportId}
            closeModal={handleClose}
            setConfirmation={isConfirmed}
          />
        );
      }
      case CONTENT_DOWNLOAD_IN_PROGRESS: {
        return (
          <div
            id="DownloadReport-Content-In-Progress"
            data-testid="DownloadReport-Content-In-Progress"
          >
            <h1
              className={`text-1xl text-left text-purple-600 uppercase font-bold text-center`}
            >
              Download In Progress
            </h1>
            <div className="py-4">
              <CircularProgress color="secondary" />
            </div>
          </div>
        );
      }
      case CONTENT_DOWNLOAD_SUCCESS: {
        return (
          <div
            id="DownloadReport-Content-Success"
            data-testid="DownloadReport-Content-Success"
          >
            <h1
              className={`text-1xl text-left text-purple-600 uppercase font-bold text-center`}
            >
              Download Complete
            </h1>
            <div className="py-4 text-center">
              You should receive an email shortly with a link to your download.
            </div>
            <Button variant="contained" className="bg-green-600" onClick={() => handleClose()}>
              OK
            </Button>
          </div>
        );
      }
      case CONTENT_DOWNLOAD_ERROR: {
        return (
          <div
            id="DownloadReport-Content-Error"
            data-testid="DownloadReport-Content-Error"
          >
            <div className="py-4">Error. Please Try Again Later.</div>
            <Button variant="contained" className="bg-red-600" onClick={() => handleClose()}>
              OK
            </Button>
          </div>
        );
      }
      default: {
        return <LoadingIndicator isLoading={true} />;
      }
    }
  };

  const handleClose = () => {
    toggleDialog(dispatch, false);
    setfetchError(dispatch, false);
  };

  return (
    <Dialog open={state.showDialog}>
      <div className="outer text-center p-2">
        <div className="text-right">
          <Button onClick={(e) => handleClose(e)}>
            <Close />
          </Button>
        </div>

        <div className="text-center p-2">
          {state.dialogIsLoading ? (
            <LoadingIndicator isLoading={true} />
          ) : (
            <div
              className="text-center p-2"
              data-testid="AddTeams-Dialog-Content"
            >
              {setUpDialogContent(state.showContent)}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default ReportModal;
