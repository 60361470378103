import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../context/DataContext";
import axios from "../../api/axios";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import ShowMoreButtonAutoCompV2 from "./ShowMoreButtonAutoCompV2";

export default function MultiSelectAutoCompleteV2({
  apiUrl,
  title,
  setVal,
  activeEditObj,
  customLimit,
  permissions,
  trigger,
  required,
  isDisabled,
  error,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [resLimit, setResLimit] = useState(customLimit ? customLimit : 25);
  const [showMoreVisible, setShowMoreVisible] = useState(false);

  useDebouncedEffect(
    () => {
      if (
        userRoles.permissions.includes(
          !permissions ? "utilities.view_fieldoptions" : permissions
        )
      ) {
        axios
          .get(
            apiUrl +
              `&limit=${resLimit}` +
              `${search ? `&search=${search}` : ""}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            setOptions(response.data.results);
            if (response.data.count <= resLimit) {
              setShowMoreVisible(false);
            } else {
              setShowMoreVisible(true);
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        return;
      }
    },
    [userRoles.permissions, accessToken, setOptions, search, resLimit, trigger],
    250
  );

  return (
    <Autocomplete
      multiple
      options={!options ? [] : options.map((option) => option)}
      getOptionLabel={(option) => option.description}
      defaultValue={activeEditObj}
      disabled={isDisabled ? true : false}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.description}
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={(e, value) => {
        let newArr = value.filter((obj, index) => {
          return index === value.findIndex((o) => obj.pk === o.pk);
        });
        setVal(newArr);
      }}
      renderInput={(params) => (
        <div className="flex items-center">
          <TextField
            {...params}
            label={title}
            onChange={(e) => setSearch(e.target.value)}
            required={!required ? false : true}
            error={error}
          />
          <ShowMoreButtonAutoCompV2
            showMoreVisible={showMoreVisible}
            setResLimit={setResLimit}
            resLimit={resLimit}
            showMoreAmt={10}
            customTitle={title}
          />
        </div>
      )}
    />
  );
}
