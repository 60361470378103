import PropTypes from 'prop-types';
import React from "react";
import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

export default function CommunicationStatus({
    fieldName,
    value,
    flag,
    clock,
})
{
    return(
      <BaseField
        id="SB-Communication-Status"
        testId="SB-Communication-Status"
        fieldName={fieldName}
        label={"Communication Status"}
        flag={flag}
        clock={clock}
      >
        {ValueWithFallback(value)}
      </BaseField>
    )
}

CommunicationStatus.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  flag: PropTypes.any,
  clock: PropTypes.any,
}
