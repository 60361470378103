import { parseISO } from "date-fns";

export const getDisplayFollowUp = (followUps = [], isFollowUpsExpanded = false) => {
  if(!isFollowUpsExpanded)
  {
  const sortedFollowUpsByDate = [...followUps].sort((a, b) => {
    const aDate = parseISO(a.due_date);
    const bDate = parseISO(b.due_date);

    return aDate.getTime() - bDate.getTime();
  });
  return sortedFollowUpsByDate[0];
}
return;
};
