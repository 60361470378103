import { useContext } from "react";
import { ACTION_TYPE } from "../types/actionTypes";
import FollowUpItem from "./FollowUpItem";
import DataContext from "../../../../../../../../../context/DataContext";
import { getDisplayFollowUp } from "../utils/getDisplayFollowUp";
import { parseISO } from "date-fns";

const getLabelFromFollowUp = (followUp) => {
  const { PROVIDER_FOLLOW_UP, LAW_FIRM_FOLLOW_UP, CLIENT_FOLLOW_UP } =
    ACTION_TYPE;
  const actionType = followUp?.action_type;

  if (actionType === PROVIDER_FOLLOW_UP) {
    return followUp?.on_provider?.name;
  }
  if (actionType === LAW_FIRM_FOLLOW_UP) {
    return followUp?.on_firm?.name;
  }
  if (actionType === CLIENT_FOLLOW_UP) {
    return followUp?.on_case?.name;
  }
};

const FollowUpItemList = ({ followUps }) => {
  const {
    isFollowUpsExpanded,
    lawFirmFollowUps,
    clientFollowUps,
    providerFollowUps,
  } = useContext(DataContext);

  const displayFollowUps = isFollowUpsExpanded
    ? followUps
    : [
        getDisplayFollowUp([
          ...lawFirmFollowUps,
          ...clientFollowUps,
          ...providerFollowUps,
        ]),
      ];
  return [...displayFollowUps]
    ?.sort((a, b) => {
      const aDate = parseISO(a.due_date);
      const bDate = parseISO(b.due_date);

      return aDate.getTime() - bDate.getTime();
    })
    ?.map((followUp) => {
      return (
        <div key={followUp?.id} className="mb-2">
          <FollowUpItem
            id={followUp.id}
            clock={followUp.clock_state}
            label={getLabelFromFollowUp(followUp)}
            name={followUp.fieldName}
            timeTillDueDate={followUp.relative_due_date}
            dueDate={followUp.due_date}
            detail={followUp.action_detail}
            status={followUp.status}
            actionType={followUp.action_type}
            completedAt={followUp.completedAt}
            completedBy={followUp.completedBy}
            updatedAt={followUp.updatedAt}
            updatedBy={followUp.updatedBy}
            client={followUp.on_case? followUp.on_case.client: ""}
            provider={followUp.on_provider? followUp.on_provider: ""}
          />
        </div>
      );
    });
};

export default FollowUpItemList;
