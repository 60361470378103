import React from "react";

const Title = ({children}) => {
  return (
    <div id="report-title" data-testid="report-title">
      <h1 className={`text-2xl text-left text-purple-600 uppercase font-bold`}>
        Reports: {children}
      </h1>
    </div>
  );
};
export default Title;
