import {
  Add as AddIcon,
  CloseOutlined as CloseOutlinedIcon,
} from "@mui/icons-material";
import { Button, Slider, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import DataContext from "../../../../../context/DataContext";

const MIN_RANGE = 0.00;
const MAX_RANGE = process.env.REACT_APP_RANGE_SLIDER_MAX || 10000000.00;
const DEFAULT_MIN_VALUE = MIN_RANGE;
const DEFAULT_MAX_VALUE = 50000.00;

const ClientSearchRangeSlider = (props) => {
  const {
    title,
    minRange = MIN_RANGE,
    maxRange = MAX_RANGE,
    minValue = DEFAULT_MIN_VALUE,
    maxValue = DEFAULT_MAX_VALUE,
    setMinValue,
    setMaxValue,
    formatter,
    resetPagination,
  } = props;

  const { trigger, setTrigger } = useContext(DataContext);

  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState([minValue, maxValue]);

  useEffect(() => {
    if (isEditing) return;
    setValues([minValue || DEFAULT_MIN_VALUE, maxValue || DEFAULT_MAX_VALUE]);
    // eslint-disable-next-line
  }, [minValue, maxValue]);

  // Handle slider change values
  const handleChange = (_, newValues) => {
    setValues(newValues);
  };

  // Handle input change for minimum value
  const handleMinInputChange = (event) => {
    const newMin = Number(event.target.value);
    setValues([newMin, values[1]]);
  };

  // Handle input change for maximum value
  const handleMaxInputChange = (event) => {
    const newMax = Number(event.target.value);
    setValues([values[0], newMax]);
  };

  // format data for slider value label
  const formatCurrency = (value) => formatter.format(value);

  const handlePost = () => {
    const [newMin, newMax] = values;
    if (setMinValue) setMinValue(newMin);
    if (setMaxValue) setMaxValue(newMax);

    setIsEditing(false);
    setTrigger(!trigger);
    if (resetPagination) resetPagination();
  };

  const clearFunc = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (setMinValue) setMinValue(undefined);
    if (setMaxValue) setMaxValue(undefined);
    setIsEditing(false);
    setTrigger(!trigger);
    setValues([DEFAULT_MIN_VALUE, DEFAULT_MAX_VALUE]);
    if (resetPagination) resetPagination();
  };

  const generateTooltipContent = () => {
    if (props.maxValue) {
      return (
        <div className="flex flex-col gap-1">
          <span>{title}</span>
          <div className="flex gap-1 items-center" data-testid="non-edit-value">
            <span className="text-sky-600">{`${formatter.format(
              values[0]
            )} - ${formatter.format(values[1])}`}</span>
            <Button
              onClick={clearFunc}
              className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
            >
              X
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex gap-1">
          <span>{title}</span>
          <AddIcon className="text-sky-600" />
        </div>
      );
    }
  };

  if (!isEditing) {
    return (
      <Tooltip title={`Edit ${title}`}>
        <button
          onClick={() => setIsEditing(true)}
          className="text-left flex"
          data-testid="edit-button"
        >
          {generateTooltipContent()}
        </button>
      </Tooltip>
    );
  }

  return (
    <div className="flex gap-1 w-full">
      <div className="flex flex-col gap-1 flex-[1_1_auto] relative z-10">
        <span>{title}</span>
        <div className="pr-2 pl-4" data-testid="range-slider">
          <Slider
            value={values}
            min={minRange}
            step={1}
            max={maxRange}
            getAriaValueText={formatCurrency}
            valueLabelFormat={formatCurrency}
            onChange={handleChange}
            valueLabelDisplay="auto"
            size="small"
          />
        </div>
        <div className="flex items-center justify-between gap-1">
          <TextField
            label="Min"
            value={values[0]}
            onChange={handleMinInputChange}
            type="number"
            className="w-full"
            size="small"
            InputProps={{ inputProps: { min: 0, max: values[1] } }}
            data-testid="min-input"
          />
          -
          <TextField
            label="Max"
            value={values[1]}
            onChange={handleMaxInputChange}
            type="number"
            className="w-full"
            size="small"
            InputProps={{ inputProps: { min: values[0], max: maxRange } }}
            data-testid="max-input"
          />
        </div>
      </div>
      <div className="relative flex-[0_0_64px] flex flex-col gap-1 justify-center">
        <Button
          onClick={handlePost}
          className="text-purple-600 hover:text-white hover:bg-purple-600 rounded-md text-sm"
        >
          Save
        </Button>
        <Button
          onClick={clearFunc}
          className="text-red-600 hover:text-white hover:bg-red-600 rounded-md text-sm"
        >
          <CloseOutlinedIcon />
        </Button>
      </div>
    </div>
  );
};

export default ClientSearchRangeSlider;

ClientSearchRangeSlider.propTypes = {
  title: PropTypes.string,
  minRange: PropTypes.number,
  maxRange: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  setMinValue: PropTypes.func,
  setMaxValue: PropTypes.func,
  formatter: PropTypes.func,
  resetPagination: PropTypes.func,
};
