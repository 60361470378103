import { createContext, useReducer } from "react";
import {
  reportsDetailReducer,
  initialState,
} from "../state/reportsDetailReducer";

export const ReportsDetailDispatchContext = createContext();
export const ReportsDetailStateContext = createContext();

const ReportsDetailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reportsDetailReducer, initialState);

  return (
    <ReportsDetailDispatchContext.Provider value={dispatch}>
      <ReportsDetailStateContext.Provider value={state}>
        {children}
      </ReportsDetailStateContext.Provider>
    </ReportsDetailDispatchContext.Provider>
  );
};

export default ReportsDetailProvider;
