import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Pagination, PaginationItem } from "@mui/material";
import React from "react";

export default function PaginationControlsV2({
  resultCount,
  handleChange,
  currentPage,
  resultLimit,
}) {
  return (
    <Pagination
      count={Math.ceil(resultCount / (resultLimit ? resultLimit : 25))}
      onChange={handleChange}
      page={currentPage}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: ArrowBack,
            next: ArrowForward,
          }}
          {...item}
        />
      )}
    />
  );
}
