import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { STRINGS } from "../../utils/strings";

const ClientType = ({ clientObj }) => {
  const navigateToDetail = (caseId) => {
    window.open(`/client-detail/${caseId}`, "_blank");
  };

  return (
    <Tooltip title={STRINGS.TOOLTIP_CLIENT_LINK}>
      <Link
        onClick={() => navigateToDetail(clientObj.id)}
        className="text-left underline text-purple-800 hover:text-black"
      >
        {clientObj.value}
      </Link>
    </Tooltip>
  );
};
export default ClientType;
