import React, { useContext } from "react";
import {
  ReportsDetailDispatchContext,
  ReportsDetailStateContext,
} from "../context/reportsDetailProvider";
import Title from "./Title";
import Search from "./Search";
import ActionsButton from "./ActionsButton";
import { setSearch } from "../state/actions";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import DataContext from "../../../../../../../../context/DataContext";

const Header = () => {
  const { userRoles } = useContext(DataContext);
  const state = useContext(ReportsDetailStateContext);
  const dispatch = useContext(ReportsDetailDispatchContext);

  return (
    <div
      id="Report-Detail-Header"
      data-testid="Report-Detail-Header"
      className=""
    >
      <div className="flex">
        <div className="flex-col flex-1">
          <Title>{!state.loading && state.reportTitle}</Title>
          <Search
            currentSearchValue={state.search}
            setSearchState={(searchStr) => {
              setSearch(dispatch, searchStr);
            }}
            searchByKeys={state.searchByKeys}
          />
        </div>
        <div className="flex-col ml-20">
          <PermissionWrapper
            permission={userRoles.permissions.includes(
              "reports.can_export_reports"
            )}
          >
            <ActionsButton />
          </PermissionWrapper>
        </div>
      </div>
    </div>
  );
};
export default Header;
