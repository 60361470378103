import React, { useReducer, useContext, useState, useEffect } from "react";
import { upmReducer, initialState } from "./state/upmReducer";
import qs from "query-string";
import UserList from "../UserList";
import SearchAutoSubmit from "../../../../../global/SearchAutoSubmit";
import { Panel } from "../UserList/components/Panel";
import Error from "./components/Error";
import PermissionWrapper from "../../../../../global/PermissionWrapper";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Box, Drawer, Divider, IconButton } from "@mui/material";
import { AddCircle, Close, OpenInNew } from "@mui/icons-material";
import FiltersMenu from "./components/FiltersMenu";
import AddUser from "../AddUser/AddUser";
import { UPMStateContext } from "./context/UPMStateContext";
import { UPMDispatchContext } from "./context/UPMDispatchContext";
import {
  searchText,
  showComponent,
  setSelectedUser,
  VIEW_EDIT_USER,
  VIEW_DEACTIVATE_USER,
  VIEW_ADD_USER,
  VIEW_PASSWORD_RESET,
  setFilterData,
  setCurrentPageSet,
  setCurrentPageUrlSet,
} from "./state/actions";
import DeActivateUser from "../DeActivateUser";
import useApiData from "./hooks/useApiData";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";
import Main from "./components/Main";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import UserProfile from "../../MyAccount/UserProfile";
import LoadingIndicator from "../../../../../global/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import PaginationControls from "../../../../../global/PaginationControls";

//TODO: IMPORTANT CHange viewable permissions
export default function UserProfileManager() {
  /**
   * User Profile Manager Component
   * - Loads Sub Modules in various layouts/positions
   * - Manages Communication between Sub Modules
   * - Determines Priority of SubModules access
   *
   */
  const STRINGS = {
    TITLE: "Manage Users",
    SUBTITLE:
      "Manage the people in your account - add, edit, archive and delete anyone at any time.",
    FILTERS_BTN: "Filters",
    ADD_USER_BTN: "Add User",
    NO_ACCESS: "You do not have permission to access.",
    SEARCH_PLACEHOLDER: "Search by Name, Email, or Phone",
  };

  const apiUrls = {
    getUsersIManage: "api/users/managed_users/",
    users: "api/users/",
  };

  const { loggedInUser, accessToken, userRoles } = useContext(DataContext);
  const [state, dispatch] = useReducer(upmReducer, initialState);
  const [open, setOpen] = useState(false);
  const [languageObj, setLanguageObj] = useState("");
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [orderingVal, setOrderingVal] = useState("-created_at");

  /********  Preloaded Data needed before Component Rendering ********/
  // eslint-disable-next-line
  const [data, error, isLoading, getData, resultCount] = useApiData(
    apiUrls.getUsersIManage,
    accessToken
  );

  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const drawerWidth = width > 765 ? "50%" : "100%";

  const can_create_user = userRoles.permissions.includes("users.add_user");
  const can_deactivate_user =
    userRoles.permissions.includes("users.delete_user");
  const can_view_user_manager =
    userRoles.permissions.includes("users.view_user");

  /*   const [managers_data, managers_error, managers_isLoading, getManagersData] =
    useApiData(apiUrls.getUsersIReportTo, accessToken); */
  /********  Preloaded Data needed before Component Rendering ********/

  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_language")) {
      axios
        .get(`/api/utilities/language/?ordering=created_at`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let obj = {};

          for (const item in data) {
            obj[data[item].pk] = data[item];
          }
          setLanguageObj(obj);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    } else {
      return;
    }
  }, [accessToken, setLanguageObj, userRoles.permissions]);

  useDebouncedEffect(
    () => {
      // if (state.search !== null) {
      let querytest = qs.stringify(
        {
          ordering: orderingVal,
          search: !state.search ? null : state.search,
          is_active: !state.filters.is_active ? null : state.filters.is_active,
          managed_by: !state.filters.managedBy ? null : state.filters.managedBy,
          last_login__gte: !state.filters.last_login__gte
            ? null
            : new Date(state.filters.last_login__gte).toLocaleDateString(
                "en-CA"
              ),
          last_login__lte: !state.filters.last_login__lte
            ? null
            : new Date(state.filters.last_login__lte).toLocaleDateString(
                "en-CA"
              ),
          password_updated_at__gte: !state.filters.password_updated_at__gte
            ? null
            : new Date(
                state.filters.password_updated_at__gte
              ).toLocaleDateString("en-CA"),
          password_updated_at__lte: !state.filters.password_updated_at__lte
            ? null
            : new Date(
                state.filters.password_updated_at__lte
              ).toLocaleDateString("en-CA"),
        },
        {
          skipNull: true,
        }
      );

      getData(
        apiUrls.getUsersIManage + "?" + state.paginationUrl + querytest,
        accessToken
      );

      // }
    },
    [
      state.search,
      state.filters,
      state.filters.managedBy,
      state.filters.status,
      state.filters.managed_by,
      state.filters.last_login__gte,
      state.filters.last_login__lte,
      state.filters.password_updated_at__gte,
      state.filters.password_updated_at__lte,
      state.currentPage,
      reloadTrigger,
      orderingVal,
    ],
    800
  );

  const handleFilterSubmit = (name, value) => {
    setCurrentPageUrlSet(dispatch, "");
    setCurrentPageSet(dispatch, 1);
    setFilterData(dispatch, {
      filterName: name,
      data: value,
    });
  };

  const handlePageChange = (event, value) => {
    setCurrentPageSet(dispatch, value);

    if (value === 1) {
      setCurrentPageUrlSet(dispatch, `limit=${state.resLimit}&`);
    }
    setCurrentPageUrlSet(
      dispatch,
      `limit=${state.resLimit}&offset=${state.resLimit * (value - 1)}&`
    );
  };

  const handleSubmitForm = async (e, data = null, dataId = null) => {
    await axios({
      url: dataId ? apiUrls.users + dataId : apiUrls.users,
      method: dataId ? "DELETE" : "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${accessToken}`,
      },
    });
    // getData(apiUrls.getUsersIManage, accessToken);
  };

  const closeActiveComponent = () => {
    showComponent(dispatch, "");
    handleDrawerClose();
  };

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  const handlePasswordResetUser = (user) => {
    setSelectedUser(dispatch, user);
    showComponent(dispatch, VIEW_PASSWORD_RESET);
  };

  const handleDeactivateUser = (user) => {
    setSelectedUser(dispatch, user);
    showComponent(dispatch, VIEW_DEACTIVATE_USER);
    handleDrawerOpen();
  };

  const handleEditUser = (user) => {
    setSelectedUser(dispatch, user);
    showComponent(dispatch, VIEW_EDIT_USER);
    handleDrawerOpen();
  };

  const handleAddUser = () => {
    showComponent(dispatch, VIEW_ADD_USER);
    handleDrawerOpen();
  };

  /******** Component Rendering ********/
  const getFiltersMenu = () => {
    return (
      <FiltersMenu
        displayText={STRINGS.FILTERS_BTN}
        handleFilterSubmit={handleFilterSubmit}
        currentFilterState={state.filters}
        /* managedBy={managers_data} */
      />
    );
  };
  const getUsersList = () => {
    return (
      <>
        <UserList
          isLoading={isLoading}
          data={data?.results}
          deactivatedUser={handleDeactivateUser}
          editUser={handleEditUser}
          resetPassword={handlePasswordResetUser}
          pageHandleChange={handlePageChange}
          currentPage={state.currentPage}
          resultCount={resultCount}
          languageObj={languageObj}
          orderingVal={orderingVal}
          setOrderingVal={setOrderingVal}
        />
        <LoadingIndicator isLoading={isLoading} />
      </>
    );
  };

  const renderHelperComponent = () => {
    switch (state.showComponent) {
      case VIEW_ADD_USER: {
        return (
          <AddUser
            permissionSets={loggedInUser.permission_sets}
            submitForm={handleSubmitForm}
            close={closeActiveComponent}
            reloadTrigger={reloadTrigger}
            setReloadTrigger={setReloadTrigger}
          />
        );
      }
      case VIEW_DEACTIVATE_USER: {
        return (
          <DeActivateUser
            selectedUser={{
              name: state.selectedUser.name,
              email: state.selectedUser.email,
              id: state.selectedUser.pk,
            }}
            submitForm={handleSubmitForm}
            close={closeActiveComponent}
            reloadTrigger={reloadTrigger}
            setReloadTrigger={setReloadTrigger}
          />
        );
      }
       case VIEW_EDIT_USER: {
        return navigate(`/profile/${state.selectedUser.pk}`);
      }
      case VIEW_PASSWORD_RESET: {
        console.log("Link to Reset Password Email Link");
        break;
      }
      default:
        return "";
    }
  };
  /********  Component Rendering ********/

  return (
    <div
      data-testid="User-Profile-Manager"
      className="bg-white w-full overflow-hidden"
    >
      <PermissionWrapper permission={can_view_user_manager}>
        <UPMStateContext.Provider value={state}>
          <UPMDispatchContext.Provider value={dispatch}>
            <Box sx={{ display: "flex", width: "100%" }}>
              <CssBaseline />
              <Drawer
                sx={{
                  //width: drawerWidth,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="right"
                open={open}
              >
                <Divider />
                <PermissionWrapper permission={can_deactivate_user}>
                  {renderHelperComponent()}
                </PermissionWrapper>
              </Drawer>
              <Main open={open} drawerwidth={drawerWidth} style={{ width: "100%" }}>
                <Panel>
                  <div className="my-2">
                    <h1 className="text-[1.8rem] font-medium">
                      {STRINGS.TITLE}
                    </h1>
                    <p>{STRINGS.SUBTITLE}</p>
                    <div className="flex justify-between mb-4">
                      <div className="flex items-center">
                        {" "}
                        <SearchAutoSubmit
                          placeholder={STRINGS.SEARCH_PLACEHOLDER}
                          onchange={(text) => {
                            searchText(dispatch, text);
                            setCurrentPageUrlSet(dispatch, "");
                            setCurrentPageSet(dispatch, 1);
                          }}
                        />{" "}
                        {getFiltersMenu()}
                      </div>
                      <PermissionWrapper permission={can_create_user}>
                        <Button
                          variant="contained"
                          onClick={(e) => handleAddUser(e)}
                        >
                          <AddCircle /> {STRINGS.ADD_USER_BTN}
                        </Button>
                      </PermissionWrapper>
                    </div>
                  </div>
                  {state.errorArray.length > 0 ? (
                    <Error />
                  ) : (
                    <div className="">
                      {getUsersList()}
                      <div className="w-fit mx-auto mt-4">
                        {" "}
                        {isLoading ? (
                          ""
                        ) : (
                          <PaginationControls
                            resultCount={resultCount}
                            handleChange={handlePageChange}
                            currentPage={state.currentPage}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Panel>
              </Main>
            </Box>
          </UPMDispatchContext.Provider>
        </UPMStateContext.Provider>
      </PermissionWrapper>
      <PermissionWrapper permission={!can_view_user_manager}>
        <div data-testid="no-access-message">{STRINGS.NO_ACCESS}</div>
      </PermissionWrapper>
    </div>
  );
}
