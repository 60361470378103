import InlineEditRow from "../../../../../../../global/InlineEditField/InlineEditRow";
import DataContext from "../../../../../../../../context/DataContext";
import { useState, useContext, useEffect } from "react";
import axios from "../../../../../../../api/axios";

export default function GeneralInfoContent({
  userInfo,
  trigger,
  setTrigger,
  myAccount,
}) {
  const [languageLabels, setLanguageLabels] = useState("");
  const { accessToken, userRoles } = useContext(DataContext);
  const can_edit_user = !userRoles.permissions.includes("users.change_user")
    ? true
    : false;
  const can_edit_usercontactinfo = !userRoles.permissions.includes(
    "users.change_usercontactinfo"
  )
    ? true
    : false;

  useEffect(() => {
    if (userRoles.permissions.includes("utilities.view_language")) {
      axios
        .get(`/api/utilities/language/?ordering=created_at`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const data = response.data.results;
          let obj = {};

          for (const item in data) {
            obj[data[item].pk] = data[item];
          }
          setLanguageLabels(obj);
          return response;
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  }, [setLanguageLabels, userRoles.permissions, accessToken]);

  return (
    <div className="p-4 m-4">
          <div id="user-name" className="w-full">
            <InlineEditRow
              title={"Name"}
              type={"text"}
              keyVal={"name"}
              displayNameKey={"Name"}
              caseDetail={userInfo}
              apiUrl={`/api/users/${userInfo.pk}/`}
              customDisplayUrl={false}
              postKeyVal={null}
              trigger={trigger}
              setTrigger={setTrigger}
              valueHistory={null}
              displayDetail={userInfo}
              valueLabels={"name"}
              disabled={can_edit_user}
              nullable={false}
              staticVal={userInfo["name"] ? userInfo["name"] : ""}
            />
          </div>
          <div id="user-email" className="w-full">
            <InlineEditRow
              title={"Email"}
              type={"text"}
              keyVal={"email"}
              displayNameKey={"Email"}
              caseDetail={userInfo}
              apiUrl={`/api/users/${userInfo.pk}/`}
              customDisplayUrl={false}
              postKeyVal={null}
              trigger={trigger}
              setTrigger={setTrigger}
              valueHistory={null}
              displayDetail={userInfo}
              valueLabels={"email"}
              disabled={can_edit_usercontactinfo}
              nullable={false}
              staticVal={userInfo["email"] ? userInfo["email"] : ""}
            />
          </div>
          <div id="user-phone" className="w-full">
            <InlineEditRow
              title={"Phone"}
              type={"text"}
              keyVal={"phone"}
              displayNameKey={"Phone"}
              caseDetail={userInfo}
              apiUrl={`/api/users/${userInfo.pk}/`}
              customDisplayUrl={false}
              postKeyVal={null}
              trigger={trigger}
              setTrigger={setTrigger}
              valueHistory={null}
              displayDetail={userInfo}
              valueLabels={"phone"}
              disabled={can_edit_usercontactinfo}
              nullable={false}
              staticVal={userInfo["phone"] ? userInfo["phone"] : ""}
            />
          </div>
          <div id="user-address" className="w-full">
            <InlineEditRow
              title={"Address"}
              type={"address"}
              keyVal={"address"}
              displayNameKey={"Address"}
              caseDetail={userInfo}
              apiUrl={`/api/users/${userInfo.pk}/`}
              customDisplayUrl={false}
              postKeyVal={null}
              trigger={trigger}
              setTrigger={setTrigger}
              valueHistory={null}
              displayDetail={userInfo}
              valueLabels={"address"}
              disabled={can_edit_usercontactinfo}
              nullable={false}
              staticVal={userInfo["address"] ? userInfo["address"] : ""}
            />
          </div>
          <div id="user-dob" className="w-full">
            <InlineEditRow
              title={"Date of Birth"}
              type={"date"}
              keyVal={"date_of_birth"}
              displayNameKey={"Date of Birth"}
              caseDetail={userInfo}
              apiUrl={`/api/users/${userInfo.pk}/`}
              customDisplayUrl={false}
              postKeyVal={null}
              trigger={trigger}
              setTrigger={setTrigger}
              valueHistory={null}
              displayDetail={userInfo}
              valueLabels={"date_of_birth"}
              disabled={can_edit_user}
              nullable={false}
              staticVal={
                userInfo["date_of_birth"] ? userInfo["date_of_birth"] : ""
              }
            />
          </div>

          <div id="user-spoke-language" className="w-full">
            <InlineEditRow
              title={"Spoken Languages"}
              type={"multi-search-select"}
              keyVal={"spoken_language"}
              displayNameKey={"description"}
              caseDetail={userInfo}
              apiUrl={`/api/users/${userInfo.pk}/`}
              customDisplayUrl={true}
              optionsUrl={
                "/api/utilities/language/?ordering=created_at&limit=1000"
              }
              customLimit={"25"}
              postKey={"pk"}
              trigger={trigger}
              setTrigger={setTrigger}
              valueHistory={null}
              displayDetail={userInfo}
              valueLabels={languageLabels}
              disabled={can_edit_user}
              nullable={false}
              staticVal={
                userInfo["spoken_language"] ? userInfo["spoken-language"] : ""
              }
            />
          </div>

          <div id="user-preferred-language" className="w-full">
            <InlineEditRow
              title={"Preferred Language"}
              type={"search-select"}
              keyVal={"preferred_language"}
              displayNameKey={"description"}
              caseDetail={userInfo}
              apiUrl={`/api/users/${userInfo.pk}/`}
              customDisplayUrl={true}
              optionsUrl={
                "/api/utilities/language/?ordering=created_at&limit=1000"
              }
              customLimit={"25"}
              postKey={"pk"}
              postKeyVal={"pk"}
              trigger={trigger}
              setTrigger={setTrigger}
              optionsDisplayKey={"description"}
              valueHistory={null}
              displayDetail={userInfo}
              valueLabels={languageLabels}
              disabled={can_edit_user}
              nullable={false}
              staticVal={
                userInfo["preferred_language"]
                  ? userInfo["preferred_language"]
                  : ""
              }
            />
          </div>
    </div>
  );
}
