import Search from "./components/Search";
import { useState, useContext, useCallback } from "react";
import axios from "../../../../../../api/axios";
import OrderingArrow from "../../../../../../global/OrderingArrow";
import DataContext from "../../../../../../../context/DataContext";
import ReportsListItem from "./ReportsListItem";
import { Button, List, CircularProgress } from "@mui/material";
import { useDebouncedEffect } from "../../../../../../hooks/useDebounceEffect";
import PaginationControls from "../../../../../../global/PaginationControls";
import {
  ReportsListDispatchContext,
  ReportsListStateContext,
} from "./context/reportsListProvider";
import { GenerateQueryParameterString } from "../../../../../../global/GenerateQueryParamString";
import {
  setSearch,
  setOrderingVal,
  setCurrentPage,
  setOffset,
} from "./state/actions";

const ReportsListWithState = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [resultCount, setResultCount] = useState({ total: "", current: "" });
  const [responseData, setResponseData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const { accessToken } = useContext(DataContext);
  const state = useContext(ReportsListStateContext);
  const dispatch = useContext(ReportsListDispatchContext);

  const updateSearch = (searchStr) => {
    setSearch(dispatch, searchStr);
  };

  const getFilterParamsList = useCallback(() => {
    let paramObj = {
      ...(state.search ? { search: state.search } : {}),
      ...(state.resLimit ? { limit: state.resLimit } : {}),
      ...(state.orderingVal ? { ordering: state.orderingVal } : {}),
      ...(state.offset ? { offset: state.offset } : {}),
    };
    return GenerateQueryParameterString(paramObj);
  }, [state.search, state.resLimit, state.orderingVal, state.offset]);

  const sendRequest = () => {
    axios
      .get(`/api/tabular_reports/${getFilterParamsList()}`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setResponseData(response.data.results);
        setResultCount({
          total: response.data.count,
          current: response.data.results.length,
        });
        setLoading(false);
      })
      .catch(function (err) {
        setError(err);
        setLoading(false);
      });
  };

  const handleReload = () => {
    setError("");
    setTrigger(!trigger);
  };

  const handleChange = (event, value) => {
    setCurrentPage(dispatch, value);
    setOffset(dispatch, state.resLimit * (value - 1));
  };

  const resetPagination = () => {
    setOffset(dispatch, null);
    setCurrentPage(dispatch, 1);
  };

  useDebouncedEffect(
    () => {
      sendRequest();
    },
    [state.search, state.orderingVal, state.offset, trigger],
    250
  );

  const setOrderValue = (orderVal) => {
    setOrderingVal(dispatch, orderVal);
  };

  return (
    <div id="Reports" data-testid="Reports" className="bg-white px-10 py-5">
      <div className="h-full justify-items-center overflow-auto sm:mx-auto w-full">
        <h1
          className={`text-2xl text-left text-purple-600 uppercase font-bold w-full shadow-md rounded-lg py-4 bg-white z-10`}
        >
          Reports
        </h1>
      </div>

      <Search setSearchState={updateSearch} resetPagination={resetPagination} />

      <div className="">
        <div className="flex flex-row w-full">
          <div className="flex w-[25%] justify-start mr-2">
            <h2 className="font-bold md:block">NAME</h2>
            {!responseData || responseData.length === 0 ? (
              ""
            ) : (
              <OrderingArrow
                val={"display_name"}
                orderingVal={state.orderingVal}
                setOrderingVal={setOrderValue}
              />
            )}
          </div>
          <h2 className="font-bold md:block">DESCRIPTION</h2>
        </div>
        <div>
          <List
            className={`flex flex-col w-full min-h-[40vh] max-h-[50vh] pb-2 overflow-y-auto`}
          >
            {loading === true ? (
              <div
                key="loading-indicator"
                className="flex justify-center my-24"
              >
                <CircularProgress color="secondary" />
              </div>
            ) : !error ? (
              <>
                {!responseData || responseData.length === 0 ? (
                  <p className="text-center">No Reports Available</p>
                ) : (
                  responseData.map((item, index) => (
                    <ReportsListItem
                      key={index}
                      report={item}
                    />
                  ))
                )}
              </>
            ) : (
              <>
                <p className="text-center">
                  An error occurred. Please try again.
                </p>
                <div className="w-full flex justify-center">
                  <Button
                    className="w-fit px-4 py-2 rounded"
                    onClick={handleReload}
                  >
                    Try again
                  </Button>
                </div>
              </>
            )}
          </List>
          {!responseData || responseData.length === 0 ? (
            ""
          ) : (
            <div className="flex justify-center flex-row">
              <div className={`flex-none justify-start py-2 px-0 float-left`}>
                <h2 className="">
                  Showing: {resultCount.current + " / " + resultCount.total}
                </h2>
              </div>
              <div className={`flex justify-center py-2 md:px-0 px-0 w-full`}>
                <PaginationControls
                  resultCount={resultCount}
                  handleChange={handleChange}
                  currentPage={state.currentPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ReportsListWithState;
