import ReportsListProvider from "./context/reportsListProvider";
import ReportListWithState from "./ReportListWithState";

const ReportList = () => {
  return (
    <ReportsListProvider>
      <ReportListWithState />
    </ReportsListProvider>
  );
};
export default ReportList;
