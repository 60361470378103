import { useCallback, useContext } from "react";
import {
  setfetchError,
  setfetchIsLoading,
  setfetchSuccess,
  setFieldData,
  setFieldDataIsLoading,
  setFieldMetaData,
  setShouldFetchData,
  setShowAddButton,
} from "../state/actions";
import ApiRequestErrorHandler from "../../../../../../../global/ApiRequestErrorHandler";
import { case_detail } from "../../../../../../../api/endpoints/case";
import { ACTION_TYPE } from "../components/types/actionTypes";
import DataContext from "../../../../../../../../context/DataContext";

export default function useAPIFieldData(
  caseId,
  actionType,
  accessToken,
  dispatch,
  state
) {
  const { setLawFirmFollowUps, setClientFollowUps, setProviderFollowUps } =
    useContext(DataContext);

  const getActionTypeKey = (actionType) => {
    if (actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP) {
      return "on_provider";
    } else if (actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP) {
      return "on_firm";
    } else {
      return "on_case";
    }
  };
  const fetch = useCallback(
    async (fieldOptionsUrls) => {
      return Promise.all(fieldOptionsUrls.map(async (url) => await url))
        .then((res) => res)
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response);
          setfetchError(dispatch, errArr);
        });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setfetchIsLoading(dispatch, true);
    setShouldFetchData(dispatch, false);

    const fieldOptionsUrls = [case_detail.getFollowUpTooltipData(accessToken)];

    if (state.ui === null) {
      fetch(fieldOptionsUrls)
        .then(async (results) => {
          if (results) {
            if (results[0] && Object.keys(results[0]).length > 0) {
              return Object.keys(results[0].results).forEach((item) => {
                if (actionType === results[0].results[item].key) {
                  let newUIObj = {
                    tooltips: results[0].results[item].additional_attributes,
                    isLoading: true,
                  };

                  setFieldMetaData(dispatch, {
                    fieldName: results[0].results[item].key,
                    data: newUIObj,
                  });
                }
              });
            }
          }
        })
        .catch((error) => {
          let errArr = ApiRequestErrorHandler(error.response);
          setfetchError(dispatch, errArr);
          setfetchIsLoading(dispatch, false);
        });
    }

    case_detail
      .getPendingFollowUpData(caseId, actionType, accessToken)
      .then((res) => {
        if (actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP) {
          setProviderFollowUps(res.results);
        } else if (actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP) {
          setLawFirmFollowUps(res.results);
        } else {
          setClientFollowUps(res.results);
        }

        if (res.results.length === 0) {
          setShowAddButton(dispatch, true);
          setFieldDataIsLoading(dispatch, {
            fieldName: actionType,
            data: true,
          });
          setFieldData(dispatch, {
            fieldName: actionType,
            data: null,
          });
          setFieldDataIsLoading(dispatch, {
            fieldName: actionType,
            data: false,
          });

          return res;
        }
        if (actionType !== ACTION_TYPE.PROVIDER_FOLLOW_UP) {
          setShowAddButton(dispatch, false);
        } else {
          setShowAddButton(dispatch, true);
        }
        if (actionType !== ACTION_TYPE.PROVIDER_FOLLOW_UP) {
          return res.results.map((item) => {
            let fieldData = {
              id: item.id,
              fieldName: actionType,
              client: {
                id: item.on_case.client.pk,
                name: item.on_case.client.name,
              },
              label:
                actionType === ACTION_TYPE.CLIENT_FOLLOW_UP
                  ? item[getActionTypeKey(actionType)].client.name
                  : item[getActionTypeKey(actionType)].name,
              action_detail: item.action_detail,
              relative_due_date: item.relative_due_date,
              due_date: item.due_date,
              clock_state: item.clock_state,
              status: item.status,
              createdBy: item.created_by,
              updatedAt: item.updated_at,
              updatedBy: item.updated_by,
              completedAt: item.completed_at,
              completedBy: item.completed_by,
            };

            if (actionType === item.action_type) {
              setFieldDataIsLoading(dispatch, {
                fieldName: actionType,
                data: true,
              });
              setFieldData(dispatch, {
                fieldName: actionType,
                data: fieldData,
              });
              setFieldDataIsLoading(dispatch, {
                fieldName: actionType,
                data: false,
              });
            }

            return null;
          });
        }
        if (actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP) {
          let provObj = {};
          res.results.forEach((item) => {
            provObj[item.id] = {
              id: item.id,
              fieldName: actionType,
              client: {
                id: item.on_case.client.pk,
                name: item.on_case.client.name,
              },
              label: item[getActionTypeKey(actionType)].name,
              action_detail: item.action_detail,
              relative_due_date: item.relative_due_date,
              due_date: item.due_date,
              clock_state: item.clock_state,
              status: item.status,
              createdBy: item.created_by,
              updatedAt: item.updated_at,
              updatedBy: item.updated_by,
              completedAt: item.completed_at,
              completedBy: item.completed_by,
              provider: {
                id: item.on_provider.pk,
                name: item.on_provider.name,
              },
            };
          });

          setFieldDataIsLoading(dispatch, {
            fieldName: actionType,
            data: true,
          });
          setFieldData(dispatch, {
            fieldName: actionType,
            data: provObj,
          });
          setFieldDataIsLoading(dispatch, {
            fieldName: actionType,
            data: false,
          });

          return provObj;
        }
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(dispatch, errArr);
        setfetchIsLoading(dispatch, false);
      });
  }, [
    dispatch,
    accessToken,
    state.ui,
    caseId,
    actionType,
    fetch,
    setProviderFollowUps,
    setLawFirmFollowUps,
    setClientFollowUps,
  ]);

  return [fetchData];
}
