import { Launch } from "@mui/icons-material";
import { IconButton, styled, Tooltip, tooltipClasses } from "@mui/material";
import PropTypes from 'prop-types';
import React from "react";
import { TooltipStyling } from "../utils/TooltipStyling";
import STRINGS from "../utils/strings";

const CaseListToolTip = ({
  caseList,
  headerTitle,
  navigateToCaseDetail,
  children,
}) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  return (
    <div id={"Case-List-Tooltip"} className='flex'>
      <HtmlTooltip
        title={
          <>
            <div>{headerTitle}</div>
            <hr />
            {caseList.map((caseItem) => (
              <div key={caseItem.id}>
                {caseItem.name ? caseItem.name : STRINGS.NO_NAME}
                <Tooltip title="Open Case Details">
                  <IconButton
                    onClick={() =>
                      navigateToCaseDetail(caseItem.id ? caseItem.id : null)
                    }
                    className="hover:bg-purple-600 hover:text-white justify-start text-purple-600 ml-1"
                  >
                    <Launch
                      fontSize="inherit"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </>
        }
      >
        {children}
      </HtmlTooltip>
    </div>
  );
};

export default CaseListToolTip;

CaseListToolTip.propTypes = {
  caseList: PropTypes.any,
  headerTitle: PropTypes.string,
  navigateToCaseDetail: PropTypes.any,
  children: PropTypes.any,
}
