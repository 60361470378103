export const SHOULD_FETCH_DATA = "SHOULD_FETCH_DATA";
export const FETCH_IS_LOADING = "FETCH_IS_LOADING";
export const FETCH_INPROGRESS = "FETCH_INPROGRESS";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_DONE = "FETCH_DONE";

export const SET_COLUMNS = "SET_COLUMNS";

export const RESLIMIT_SET = "RESLIMIT_SET";
export const RESULT_COUNT_SET = "RESULT_COUNT_SET";
export const SET_OFFSET = "SET_OFFSET";
export const CURRENT_PAGE_SET = "CURRENT_PAGE_SET";

export const ORDERING_BY_SET = "ORDERING_BY_SET";
export const ORDERING_DIR_SET = "ORDERING_DIR_SET";

export const SET_SEARCH = "SET_SEARCH";

export const SET_FILTER_PARAM_LIST = "SET_FILTER_PARAM_LIST";

export const SET_SELECTED_PROVIDER = "SET_SELECTED_PROVIDER";
export const SET_REPORT_TITLE = "SET_REPORT_TITLE";
export const SET_SEARCH_DELAYED = "SET_SEARCH_DELAYED";

export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const TOGGLE_DIALOG_IS_LOADING = "TOGGLE_DIALOG_IS_LOADING";
export const SHOW_CONTENT_SET = "SHOW_CONTENT_SET";

export const CONTENT_DOWNLOAD_CONFIRMATION = "CONTENT_DOWNLOAD_CONFIRMATION";
export const CONTENT_DOWNLOAD_IN_PROGRESS = "CONTENT_DOWNLOAD_IN_PROGRESS";
export const CONTENT_DOWNLOAD_SUCCESS = "CONTENT_DOWNLOAD_SUCCESS";
export const CONTENT_DOWNLOAD_ERROR = "CONTENT_DOWNLOAD_ERROR";

export const setShouldFetchData = (dispatch, bool) =>
  dispatch({ type: SHOULD_FETCH_DATA, payload: bool });

export const setfetchIsLoading = (dispatch, bool) =>
  dispatch({ type: FETCH_IS_LOADING, payload: bool });

export const setfetchInProgress = (dispatch, obj) =>
  dispatch({ type: FETCH_INPROGRESS, payload: obj });

export const setfetchSuccess = (dispatch, bool) =>
  dispatch({ type: FETCH_SUCCESS, payload: bool });

export const setfetchError = (dispatch, arr) =>
  dispatch({ type: FETCH_ERROR, payload: arr });

export const setfetchDone = (dispatch, bool) =>
  dispatch({ type: FETCH_DONE, payload: bool });

export const setColumns = (dispatch, obj) =>
  dispatch({ type: SET_COLUMNS, payload: obj });

export const setResetLimit = (dispatch, num) =>
  dispatch({ type: RESLIMIT_SET, payload: num });

export const setResultCount = (dispatch, num) =>
  dispatch({ type: RESULT_COUNT_SET, payload: num });

export const setCurrentPage = (dispatch, num) =>
  dispatch({ type: CURRENT_PAGE_SET, payload: num });

export const setOrderingBy = (dispatch, str) =>
  dispatch({ type: ORDERING_BY_SET, payload: str });

export const setOrderingDirection = (dispatch, str) =>
  dispatch({ type: ORDERING_DIR_SET, payload: str });

export const setSearch = (dispatch, str) =>
  dispatch({ type: SET_SEARCH, payload: str });

export const setSearchDelayed = (dispatch, str) =>
  dispatch({ type: SET_SEARCH_DELAYED, payload: str });

export const setFilterParamsList = (dispatch, str) =>
  dispatch({ type: SET_FILTER_PARAM_LIST, payload: str });

export const setOffset = (dispatch, str) =>
  dispatch({ type: SET_OFFSET, payload: str });

export const setSelectedProvider = (dispatch, obj) =>
  dispatch({ type: SET_SELECTED_PROVIDER, payload: obj });

export const setReportTitle = (dispatch, str) =>
  dispatch({ type: SET_REPORT_TITLE, payload: str });

export const toggleDialog = (dispatch, bool) =>
  dispatch({ type: TOGGLE_DIALOG, payload: bool });

export const toggleDialogIsLoading = (dispatch, bool) =>
  dispatch({ type: TOGGLE_DIALOG_IS_LOADING, payload: bool });

export const setShowContent = (dispatch, str) =>
  dispatch({ type: SHOW_CONTENT_SET, payload: str });
