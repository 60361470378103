import { Tabs, Box, Tab } from "@mui/material";
export default function TabHeader({ tabList, tabValue, handleChange }) {
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        padding: "0",
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        className="justify-between "
        variant="fullWidth"
        TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
        sx={{
          "& button.Mui-selected": {
            color: `#9333EA`,
          },
          ".Mui-selected": {
            color: `#9333EA`,
          },
          ".MuiBox-root.div": {
            padding: 0,
          },
        }}
      >
        {tabList.map((tab, index) => (
          <Tab
            key={tab.id}
            label={tab.name}
            className={"w-1/2"}
            value={index}
          />
        ))}
      </Tabs>
    </Box>
  );
}
