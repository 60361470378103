import React from "react";
import { Button } from "@mui/material";

const DownloadConfirmation = ({ reportName, closeModal, setConfirmation }) => {
 
  return (
    <div
      id="Content-Confirm-Delete-Law-Firm-Teams"
      data-testid="Content-Confirm-Delete-Law-Firm-Teams"
    >
      <div className="text-xl text-center py-4">
        Are you sure you want to download {reportName}?
      </div>

      <Button
        className="m-2 capitalize border-solid-2 border-blue-500  hover:text-blue-500"
        variant="outlined"
        onClick={() => closeModal()}
      >
        Cancel
      </Button>
      <Button
      variant="contained"
        className="m-2 capitalize"
        onClick={() => setConfirmation(true)}
      >
        Download
      </Button>
    </div>
  );
};
export default DownloadConfirmation;
