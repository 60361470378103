import PropTypes from 'prop-types';
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../context/DataContext";
import Colors from "../../../../../../global/Colors";
import CaseSummaryContext from "../../CaseSummaryV2/context/CaseSummaryContext";
import {
  StaticBarDispatchContext,
  StaticBarStateContext,
} from "../context/StaticBarContext";
import useAPIFieldData from "../hooks/useAPIFieldData";
import { setShouldFetchData } from "../state/actions";
import CaseSummary from "./sections/CaseSummary";
import ClientInfoSummary from "./sections/ClientInfoSummary";
import CoverageSummary from "./sections/CoverageSummary";
import QuickActions from "./sections/QuickActions";
import StatusSummary from "./sections/StatusSummary";

const SBar = ({
  caseId,
  docNeedsType,
  setUploadDocument,
  setViewDocuments,
  setOpenExpense,
}) => {
  const state = useContext(StaticBarStateContext);
  const dispatch = useContext(StaticBarDispatchContext);
  const { accessToken, trigger } = useContext(DataContext);
  const [fetchData] = useAPIFieldData(caseId, accessToken, dispatch, state);
  const { caseSummaryTrigger, coverageTrigger, policyTrigger } =
    useContext(CaseSummaryContext);
  const params = useParams();

  useEffect(() => {
    let active = true;
    if (active) {
      if (state && !state.fetchIsLoading && state.shouldFetchData) {
        fetchData();
      }
    }
    return () => {
      active = false;
    };
  }, [state, caseId, accessToken, fetchData]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (
        !caseSummaryTrigger ||
        !policyTrigger ||
        !coverageTrigger ||
        params ||
        trigger
      ) {
        setShouldFetchData(dispatch, true);
      }
    }
    return () => {
      active = false;
    };
  }, [
    caseSummaryTrigger,
    dispatch,
    policyTrigger,
    coverageTrigger,
    params,
    trigger,
  ]);

  return (
    <div className="w-full">
      {state && !state.fetchIsLoading && !state.shouldFetchData && (
        <div
          className="flex p-2 border-1 rounded-md"
          style={{
            backgroundColor:
              state.data?.overall_status?.value === "Lost"
                ? Colors.red[100]
                : Colors.white,
          }}
        >
          <div className="flex-1">
            <div className="flex">
              {state.data && state.ui && (
                <>
                  <ClientInfoSummary />
                  <CaseSummary />
                  <CoverageSummary />
                  <StatusSummary />
                </>
              )}
            </div>
          </div>
          <div>
            <QuickActions
              docNeedsType={docNeedsType}
              setUploadDocument={setUploadDocument}
              setViewDocuments={setViewDocuments}
              setOpenExpense={setOpenExpense}
            />
          </div>
        </div>
      )}
    </div>
  );
};

SBar.propTypes = {
  caseId: PropTypes.string,
  docNeedsType: PropTypes.any,
  setUploadDocument: PropTypes.func,
  setViewDocuments:PropTypes.func,
  setOpenExpense:PropTypes.func,
}

export default SBar;
