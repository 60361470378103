import React, { useState, useEffect, useContext } from "react";
import UpdatedByAtTooltip from "../../StaticBar/components/ui/UpdatedByAtTooltip/UpdatedByAtTooltip";
import { Info } from "@mui/icons-material";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import PropTypes from "prop-types";


const HoverTooltipWithApi = ({ provider }) => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const { accessToken } = useContext(DataContext);

  useEffect(() => {
    if (isHovered && !hasFetched) {
      setLoading(true);
      axios
        .get(`/api/pro_cli_status/${provider?.pk}/history/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const result = response?.data;
          setName(result?.current_balance?.user);
          setDate(result?.current_balance?.timestamp);
          setHasFetched(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isHovered, hasFetched, provider?.pk, accessToken]);

  return (
    <UpdatedByAtTooltip
      id="example_tooltip"
      title="Last Modified"
      date={date}
      name={name}
      className="tooltip-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      loading={loading}
    >
      <span className="cursor-default text-slate-700">
        <Info fontSize="inherit" />
      </span>
    </UpdatedByAtTooltip>
  );
};

HoverTooltipWithApi.prototype = {
  provider: PropTypes.object,
};

export default HoverTooltipWithApi;
