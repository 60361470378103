import NoAvailableFollowUps from "./NoAvailableFollowUps";
import Header from "./Header";
import FollowUpModal from "../../../FollowUpModal";
import {
  FollowUpDispatchContext,
  FollowUpStateContext,
} from "../../context/FollowUpContext";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import DataContext from "../../../../../../../../../context/DataContext";
import useAPIFieldData from "../../hooks/useAPIFieldData";
import { setCaseInfo, setShouldFetchData } from "../../state/actions";
import { ACTION_TYPE } from "../types/actionTypes";
import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import FollowUpItemList from "./FollowUpItemList";

const { PROVIDER_FOLLOW_UP, LAW_FIRM_FOLLOW_UP, CLIENT_FOLLOW_UP } =
  ACTION_TYPE;

const FollowUpSection = ({
  actionType,
  caseInfo,
  title,
  icon,
  lawFirmFollowUps,
  clientFollowUps,
  providerFollowUps,
}) => {
  const params = useParams();
  const state = useContext(FollowUpStateContext);
  const dispatch = useContext(FollowUpDispatchContext);
  const { accessToken } = useContext(DataContext);
  const [fetchData] = useAPIFieldData(
    params.id,
    actionType,
    accessToken,
    dispatch,
    state
  );

  useEffect(() => {
    let active = true;
    if (active) {
      if (state && !state.fetchIsLoading && state.shouldFetchData) {
        fetchData();
      }
    }
    return () => {
      active = false;
    };
  }, [state, accessToken, fetchData]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (params) {
        setShouldFetchData(dispatch, true);
      }
    }
    return () => {
      active = false;
    };
  }, [dispatch, params]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (caseInfo) {
        setCaseInfo(dispatch, caseInfo);
      }
    }
    return () => {
      active = false;
    };
  }, [dispatch, caseInfo]);

  const renderFollowUpList = () => {
    if (state?.shouldFetchData) {
      return <CircularProgress />;
    }

    let followUps = [];
    if (actionType === PROVIDER_FOLLOW_UP) {
      followUps = providerFollowUps;
    } else if (actionType === LAW_FIRM_FOLLOW_UP) {
      followUps = lawFirmFollowUps;
    } else if (actionType === CLIENT_FOLLOW_UP) {
      followUps = clientFollowUps;
    }

    if (followUps?.length === 0) {
      return <NoAvailableFollowUps />;
    }

    return <FollowUpItemList followUps={followUps} />;
  };

  return (
    <>
      <div className="m-2 mb-2 last:mt-0">
        <Header actionType={actionType} title={title} icon={icon} />
        {renderFollowUpList()}
      </div>
      <FollowUpModal
        title={title}
        actionType={actionType}
        state={state}
        dispatch={dispatch}
      />
    </>
  );
};

FollowUpSection.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  actionType: PropTypes.number,
  caseInfo: PropTypes.object,
  lawFirmFollowUps: PropTypes.array,
  clientFollowUps: PropTypes.array,
  providerFollowUps: PropTypes.array,
};

export default FollowUpSection;
