import { createContext, useReducer } from "react";
import { reportsListReducer, initialState } from "../state/reportsListReducer";

export const ReportsListDispatchContext = createContext();
export const ReportsListStateContext = createContext();

const ReportsListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reportsListReducer, initialState);

  return (
    <ReportsListDispatchContext.Provider value={dispatch}>
      <ReportsListStateContext.Provider value={state}>
        {children}
      </ReportsListStateContext.Provider>
    </ReportsListDispatchContext.Provider>
  );
};

export default ReportsListProvider;
