import ReportsList from "./components/ReportsList";

const Reports = () => {
  return (
    <div id="Reports" data-testid="Reports">
      <ReportsList />
    </div>
  );
};

export default Reports;
