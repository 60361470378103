import { useCallback } from "react";
import {
  setColumns,
  setfetchError,
  setfetchInProgress,
  setfetchIsLoading,
  setfetchSuccess,
  setReportTitle,
  setResultCount,
  setShouldFetchData,
} from "../state/actions";
import ApiRequestErrorHandler from "../../../../../../../global/ApiRequestErrorHandler";
import { reports } from "../../../../../../../api/endpoints/reports";

export default function useGetReportDetail(
  reportId,
  queryParams,
  accessToken,
  dispatch
) {
  const fetchReportDetail = useCallback(() => {
    setfetchIsLoading(dispatch, true);
    setShouldFetchData(dispatch, false);

    reports
      .getFinancialReportDetail(reportId, queryParams, accessToken, dispatch)
      .then((res) => {
        setResultCount(dispatch, {
          total: res.count,
          current: res.results.length,
        });

        setfetchInProgress(dispatch, res.results);
        if(res.display_name){
          setReportTitle(dispatch, res.display_name);
        }
        setColumns(dispatch, res.columns);
        return res;
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(dispatch, errArr);
        setfetchIsLoading(dispatch, false);
      });
  }, [reportId, queryParams, accessToken, dispatch]);

  return [fetchReportDetail];
}
