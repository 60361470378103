import { format, parseISO } from "date-fns";
import { STRINGS } from "../../utils/strings";

const emptyString = STRINGS.EMPTY;

const formattedDate = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  if (value === "" || value === " ") {
    return emptyString;
  }
  return format(parseISO(value), "M/d/yyyy");
};

const formattedTime = (value) => {
  if (!value || value === undefined || value === null) {
    return emptyString;
  }
  if (value === "" || value === " "|| !value.includes('T')) {
    return emptyString;
  }
  return format(parseISO(value), "h:mm aaa");
};

const separator = () => {
  return " ";
};

const lineBreakSeparator = () => {
  return "\n";
};

const DateType = ({ date, newLine = false }) => {
  return (
    <div>
      {formattedDate(date).toString() +
        separator() +
        (newLine ? lineBreakSeparator() : "") +
        formattedTime(date).toString()}
    </div>
  );
};
export default DateType;
