import PropTypes from 'prop-types';
import { useContext } from "react";
import DataContext from "../../../../../../../../context/DataContext";
import AddLiensBalances from "../fields/AddLiensBalances";
import OpenDocumentList from "../fields/OpenDocumentList";
import UploadDocument from "../fields/UploadDocument";
import ViewSettlementDetail from "../fields/ViewSettlementDetail";

const QuickActions = ({
  docNeedsType,
  setUploadDocument,
  setViewDocuments,
  setOpenExpense
}) => {
  const { userRoles } = useContext(DataContext);

  return (
    <div className='flex flex-col justify-center'>
      <ViewSettlementDetail
        permission={userRoles.permissions.includes(
          "provider.view_providersettlement"
        )}
      />
      <AddLiensBalances
        permission={userRoles.permissions.includes(
          "provider.view_additionalexpense"
        )}
        setOpenExpense={setOpenExpense}
      />
      <UploadDocument
        permission={userRoles.permissions.includes("filemanager.add_document")}
        docNeedsType={docNeedsType}
        setUploadDocument={setUploadDocument}
        setViewDocuments={setViewDocuments}
      />
      <OpenDocumentList
        permission={userRoles.permissions.includes("filemanager.add_document")}
        setViewDocuments={setViewDocuments}
      />
    </div>
  );
};

export default QuickActions;

QuickActions.propTypes = {
  docNeedsType: PropTypes.any,
  setUploadDocument: PropTypes.func,
  setViewDocuments: PropTypes.func,
  setOpenExpense: PropTypes.func
}
