import { styled, Tooltip, tooltipClasses } from '@mui/material';
import PropTypes from 'prop-types';
import BaseField from '../ui/BaseField';
import ValueWithFallback from '../ui/ValueWithFallback';
import { TooltipStyling } from '../utils/TooltipStyling';

const ProvidersOutNetwork = ({ fieldName, value, flag, clock, title }) => {
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling
  }));

  return (
    <BaseField
      id="SB-Providers-Out"
      testId="SB-Providers-Out"
      fieldName={fieldName}
      label={'Out-of-Network'}
      value={value}
      flag={flag}
      clock={clock}
    >
      <HtmlInfoTooltip title={`Number of Providers: ${title}`}>
        {ValueWithFallback(value, true)}
      </HtmlInfoTooltip>
    </BaseField>
  );
};

export default ProvidersOutNetwork;

ProvidersOutNetwork.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  flag: PropTypes.any,
  clock: PropTypes.any,
  title: PropTypes.string,
}
