import ControlPointOutlined from "@mui/icons-material/ControlPointOutlined";
import {
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ModalShell from "../../../../../../global/ModalShell";
import AddClientProviderModal from "../AddClientProviderModal";
import { AspectRatioOutlined, MedicalServices } from "@mui/icons-material";
import DataContext from "../../../../../../../context/DataContext";
import ProviderListItem from "./ProviderListItem";
import ProviderListItemMobile from "./ProviderListItemMobile";
import ShowMoreButton from "../../../../../../global/ShowMoreButton";
import OrderingArrow from "../../../../../../global/OrderingArrow";
import axios from "../../../../../../api/axios";

export default function ProviderList({
  proCliResponse,
  providerAmountObj,
  formatter,
  activeProvObj,
  proCliTrigger,
  setProCliTrigger,
  setProvidersOpen,
  providersOpen,
  showMoreProvVisible,
  setProvResLimit,
  provResLimit,
  orderingVal,
  setOrderingVal,
  loading,
  caseInfo,
}) {
  const { userRoles, accessToken } = useContext(DataContext);
  const [openAddProv, setOpenAddProv] = useState(false);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [docTypeOptionsObj, setDocTypeOptionsObj] = useState({});

  useEffect(() => {
    axios
      .get(
        `/api/field_options/?field_name=medical_documents_status&ordering=order`,
        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setDocTypeOptions(response.data.results);
        let data = response.data.results;
        let obj = {};
        for (const item in data) {
          obj[data[item].key] = data[item].label;
        }
        setDocTypeOptionsObj(obj);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setDocTypeOptions, accessToken]);

  return (
    <div className="mx-2">
      <div className="flex right-2 w-full justify-end">
        {!providersOpen ? (
          <IconButton
            className="p-0 block text-blue-600"
            onClick={() => setProvidersOpen(true)}
          >
            <AspectRatioOutlined />
          </IconButton>
        ) : (
          ""
        )}
        &nbsp;
        <IconButton
          className="p-0 block text-green-600"
          onClick={() => setOpenAddProv(true)}
        >
          <ControlPointOutlined />
        </IconButton>
      </div>
      <List className="w-full border-b border-gray-300 pb-0 relative justify-between hidden lg:flex">
        <ListItem className="w-1/5 justify-center py-0">
          <h2 id="provider" className="font-bold">
            Provider
          </h2>
        </ListItem>
        <ListItem className="w-1/5 justify-center py-0">
          <h2 id="status" className="font-bold">
            Status
          </h2>
        </ListItem>
        <ListItem className="w-1/5 justify-center py-0">
          <h2 id="totals" className="font-bold">
            Totals
          </h2>
        </ListItem>
        <ListItem className="w-1/5 justify-center py-0">
          <h2 id="created" className="font-bold">
            Created
            <OrderingArrow
              val={"created_at"}
              orderingVal={orderingVal}
              setOrderingVal={setOrderingVal}
            />
          </h2>
        </ListItem>
        <ListItem className="w-[15%] justify-center py-0">
          <h2 id="first-appt" className="font-bold">
            First Appt{" "}
            <OrderingArrow
              val={"first_appointment_date"}
              orderingVal={orderingVal}
              setOrderingVal={setOrderingVal}
            />
          </h2>
        </ListItem>
        <ListItem className="w-[5%]">&nbsp;</ListItem>
      </List>
      <List className="overflow-y-auto max-h-[49vh]">
        {loading ? (
          <div className="mx-auto w-fit">
            <CircularProgress
              color="secondary"
              className="w-fit mx-auto text-center"
            />
          </div>
        ) : proCliResponse.length === 0 ? (
          <div className="w-full">
            <p className="text-center my-2 text-[2rem] text-purple-600 font-bold">
              No Results
            </p>
            <div className="w-fit mx-auto">
              <Button
                variant="outlined"
                className="text-green-600 border-green-600 text-center"
                endIcon={<MedicalServices className="text-green-600" />}
                onClick={() => setOpenAddProv(true)}
              >
                Add Provider
              </Button>
            </div>
          </div>
        ) : (
          proCliResponse.map((prov, idx) => (
            <div key={idx}>
              <ProviderListItem
                prov={prov}
                providerAmountObj={providerAmountObj}
                userRoles={userRoles}
                formatter={formatter}
                activeProvObj={activeProvObj}
                proCliTrigger={proCliTrigger}
                setProCliTrigger={setProCliTrigger}
                docTypeOptions={docTypeOptions}
                docTypeOptionsObj={docTypeOptionsObj}
                caseInfo={caseInfo}
              />
              <ProviderListItemMobile
                prov={prov}
                providerAmountObj={providerAmountObj}
                userRoles={userRoles}
                formatter={formatter}
                activeProvObj={activeProvObj}
                proCliTrigger={proCliTrigger}
                setProCliTrigger={setProCliTrigger}
                docTypeOptions={docTypeOptions}
                docTypeOptionsObj={docTypeOptionsObj}
                caseInfo={caseInfo}
              />
            </div>
          ))
        )}
        <ShowMoreButton
          showMoreVisible={showMoreProvVisible}
          setResLimit={setProvResLimit}
          resLimit={provResLimit}
          showMoreAmt={5}
        />
      </List>

      <ModalShell
        open={openAddProv}
        setOpen={setOpenAddProv}
        color="#1976d2"
        title="Add Provider"
        fullWidth
      >
        <AddClientProviderModal
          setProvFormDisplay={setOpenAddProv}
          trigger={proCliTrigger}
          setTrigger={setProCliTrigger}
          caseInfo={caseInfo}
        />
      </ModalShell>
    </div>
  );
}
