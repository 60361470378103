import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { AspectRatioOutlined, Close as CloseIcon } from "@mui/icons-material";
import PropTypes from 'prop-types';

const getButtonStyles = (action) => {
  const baseStyles = {
    marginLeft: "0 !important",
    textTransform: "none",
    minWidth: {
      xs: "100%",
      sm: "250px",
      md: "181px",
    },
    paddingInline: "12px",
    color: "white",
    backgroundColor: action.isLoading ? "#ddd" : "#5248E6",
    height: "31px",
    lineHeight: "31px",
    fontWeight: 400,
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#3128b8",
    },
  };

  const outlinedStyles = action.variant === "outlined" && {
    border: action.isLoading ? "none" : "1px solid #5248E6",
    color: "#5248E6",
    backgroundColor: action.isLoading ? "#ddd" : "white",
    "&:hover": {
      backgroundColor: "#3b3fc0",
      color: "white",
    },
  };

  return {
    ...baseStyles,
    ...(outlinedStyles || {}),
  };
};

export const ModalButton = ({
  label,
  isLoading,
  onClick,
  className,
  variant,
}) => {
  return (
    <Button
      sx={getButtonStyles({ isLoading, variant })}
      disabled={isLoading}
      onClick={onClick}
      className={className}
    >
      {isLoading ? (
        <CircularProgress
          size={25}
          sx={{
            color: "white",
          }}
        />
      ) : (
        label
      )}
    </Button>
  );
};

const Modal = ({
  open,
  onClose,
  onExpand,
  title,
  subtitle,
  children,
  actions,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        fontFamily: "Roboto",
        color: "black",
        "& .MuiPaper-root": {
          borderRadius: "8px",
        },
      }}
      {...props}
    >
      {onExpand && (
        <Tooltip title="Navigate to detail">
          <IconButton
            aria-label="navigate-to-detail"
            onClick={onExpand}
            className="absolute left-2 top-2 text-black"
          >
            <AspectRatioOutlined />
          </IconButton>
        </Tooltip>
      )}
      {/* Close Section */}
      <IconButton
        aria-label="close"
        onClick={onClose}
        className="absolute right-2 top-2 text-black"
      >
        <CloseIcon />
      </IconButton>
      {/* Title Section */}
      {title && (
        <DialogTitle className="mt-14">
          <Typography className="text-center capitalize text-2xl text-black">
            {title}
          </Typography>

          {subtitle && (
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className="text-center mt-1 text-base"
            >
              {subtitle}
            </Typography>
          )}
        </DialogTitle>
      )}

      {/* Content Section */}
      {children && <DialogContent>{children}</DialogContent>}

      {/* Action Buttons */}
      <DialogActions className="flex flex-col mb-10 gap-4 px-6">
        {actions?.map((action) => (
          <ModalButton key={action.label} {...action} />
        ))}
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  props: PropTypes.object,
};

export default Modal;
