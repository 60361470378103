import {
  CURRENT_PAGE_SET,
  CURRENT_PAGE_URL_SET,
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  ORDERING_VAL_SET,
  RESLIMIT_SET,
  RESULT_COUNT_SET,
  SET_SEARCH,
  SHOULD_FETCH_DATA,
  SET_OFFSET,
} from "./actions";

export const initialState = {
  shouldFetchData: true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  data: null,
  search: null,
  resLimit: 25,
  currentPage: 1,
  paginationUrl: null,
  resultCount: { total: "", current: "" },
  orderingVal: null,
  offset: null,
};

export function reportsListReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return { ...state, shouldFetchData: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case RESLIMIT_SET: {
      return { ...state, resLimit: action.payload };
    }
    case RESULT_COUNT_SET: {
      return { ...state, resultCount: action.payload };
    }
    case CURRENT_PAGE_SET: {
      return { ...state, currentPage: action.payload };
    }
    case CURRENT_PAGE_URL_SET: {
      return { ...state, paginationUrl: action.payload };
    }
    case ORDERING_VAL_SET: {
      return { ...state, orderingVal: action.payload };
    }
    case SET_SEARCH: {
      return { ...state, search: action.payload };
    }
    case SET_OFFSET: {
      return { ...state, offset: action.payload };
    }

    default:
      return state;
  }
}
