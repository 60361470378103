import { IconButton, styled, Tooltip, tooltipClasses } from "@mui/material";
import PropTypes from 'prop-types';
import React from "react";
import { TooltipStyling } from "../utils/TooltipStyling";

export default function ActionButton({ title, icon, action }) {
  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  return (
    <HtmlInfoTooltip title={title} placement="right">
      <IconButton
        onClick={() => action()}
        className={`hover:bg-sky-600 hover:text-white border-none text-sky-600`}
      >
        {icon}
      </IconButton>
    </HtmlInfoTooltip>
  );
}

ActionButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  action: PropTypes.func,
}
