import UpdatedAtModel from "./UpdatedAtModel";
import UpdatedByModel from "./UpdatedByModel";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "../../utils/TooltipStyling";
import PropTypes from "prop-types";

const UpdatedByAtTooltip = ({
  id,
  title,
  date,
  name,
  children,
  className,
  loading,
  onMouseEnter,
  onMouseLeave,
}) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  const renderTitle = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (!name) {
      return <div>No data</div>;
    }
  
    return (
      <>
        <div>{title}</div>
        <hr />
        <div>Updated By: {UpdatedByModel(name)}</div>
        <div>Updated At: {UpdatedAtModel(date)}</div>
      </>
    );
  };

  return (
    <span
      id={id}
      role="tooltip"
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <HtmlTooltip title={renderTitle()}>{children}</HtmlTooltip>
    </span>
  );
};

UpdatedByAtTooltip.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default UpdatedByAtTooltip;
