import { Cake, Square } from "@mui/icons-material";
import PropTypes from 'prop-types';
import BaseField from "../../ui/BaseField";
import InfoToolTip from "../../ui/InfoToolTip";
import ValueWithFallback from "../../ui/ValueWithFallback";
import STRINGS from "../../utils/strings";
import Age from "./Age";
import AgeModel from "./AgeModel";
import BirthDateModel from "./BirthDateModel";

export const BirthDate = ({
  fieldName,
  dateOfBirth,
  age,
  minorCheck,
  needsValidation,
  flag,
  clock,
}) => {
  return (
    <BaseField
      id="SB-Birthdate"
      testId="SB-Birthdate"
      fieldName={fieldName}
      needsValidation={needsValidation}
      flag={null}
      clock={clock}
    >
      <div className='h-[23px]'>
        <Cake fontSize="inherit" />
        {dateOfBirth
          ? BirthDateModel(dateOfBirth)
          : ValueWithFallback(dateOfBirth)}{" "}
        {age >= 0 && (
          <InfoToolTip
            itemList={STRINGS.DO_NOT_CONTACT}
            headerTitle={"Age"}
            icon={<Square fontSize="inherit" />}
            id={fieldName + "_tooltip"}
          >
            <span className="cursor-default">
              <Age
                age={AgeModel(ValueWithFallback(age, true))}
                minorCheck={minorCheck}
              />
            </span>
          </InfoToolTip>
        )}
      </div>
    </BaseField>
  );
};

BirthDate.propTypes = {
  fieldName: PropTypes.any,
  dateOfBirth: PropTypes.any,
  age: PropTypes.any,
  minorCheck: PropTypes.any,
  needsValidation: PropTypes.any,
  flag: PropTypes.any,
  clock: PropTypes.any,
}
