import { ListItem } from "@mui/material";
import { Link } from "react-router-dom";

export default function ReportsListItem({ report }) {
  return (
    <Link to={"/reports/" + report.pk}>
      <ListItem className="group w-full h-fit rounded border-b-2 border-slate-200 px-0 hover:bg-violet-700 hover:cursor-pointer">
        <div className="group-hover:text-white rounded flex w-[25%] justify-between mr-2">
          <h3 className="p-1 md:block">
            {!report.display_name ? "Untitled" : report.display_name}
          </h3>
        </div>
        <h3 className="group-hover:text-white md:block">
          {!report.description ? "No Description" : report.description}
        </h3>
      </ListItem>
    </Link>
  );
}
