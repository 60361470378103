import FollowUpSection from "./ui/FollowUpSection";
import FollowUpProvider from "../context/FollowUpContext";
import PropTypes from "prop-types";
import { useContext } from "react";
import DataContext from "../../../../../../../../context/DataContext";

const FollowUpViews = ({ actionType, caseInfo, limit, title, icon }) => {
  const { lawFirmFollowUps, clientFollowUps, providerFollowUps } =
    useContext(DataContext);

  return (
    <FollowUpProvider>
      <FollowUpSection
        title={title}
        icon={icon}
        limit={limit}
        actionType={actionType}
        caseInfo={caseInfo}
        lawFirmFollowUps={lawFirmFollowUps}
        clientFollowUps={clientFollowUps}
        providerFollowUps={providerFollowUps}
      />
    </FollowUpProvider>
  );
};

FollowUpViews.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  limit: PropTypes.number,
  actionType: PropTypes.number,
  caseInfo: PropTypes.object,
};

export default FollowUpViews;
