import { MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import DataContext from "../../../../../../context/DataContext";
import axios from "../../../../../api/axios";

import ResponseModal from "../../../../../global/ResponseModal";
import Modal from "../../../../../global/Modal";
import FormField from "../../../../../global/FormComponents/field/FormField";
import { inputStyles, selectStyles } from "../../constant";
import PlacesResponseListV2 from "../../../../../global/PlaceResponseListV2";

export default function CustomProvModal({ openCustomProv, setOpenCustomProv }) {
  const { accessToken } = useContext(DataContext);
  const [outOfNetworkProv, setOutOfNetworkProv] = useState("");
  const [outOfNetworkPhone, setOutOfNetworkPhone] = useState("");
  const [outOfNetworkType, setOutOfNetworkType] = useState("");
  const [provLatLng, getProvLatLng] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [address, setAddress] = useState("");
  const [provTypeOptions, setProvTypeOptions] = useState([]);

  const handlePlaceSelect = async (value) => {
    setAddress(value);
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => getProvLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(!openSuccess);
    setOutOfNetworkProv("");
    setAddress("");
    setOutOfNetworkPhone("");
    setOutOfNetworkType("");
  };

  const handleNewProviderSubmit = (e) => {
    const newProvData = {
      name: outOfNetworkProv,
      type: outOfNetworkType,
      address: {
        raw: address,
        latitude: provLatLng.lat,
        longitude: provLatLng.lng,
      },
      phone: outOfNetworkPhone,
      in_network: false,
    };

    axios
      .post("/api/provider/", newProvData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
        }
        setOpenError(!openError);
      });
  };

  useEffect(() => {
    let arrayVals = [];

    if (errorArray.name) {
      arrayVals.push(`provider: ${errorArray.name}`);
    }

    if (errorArray.phone) {
      arrayVals.push(`phone: ${errorArray.phone}`);
    }

    if (errorArray.address) {
      for (const [key, value] of Object.entries(errorArray.address)) {
        arrayVals.push(`${key}: ${value}`);
      }
    }
    setErrorMessage(arrayVals);
  }, [errorArray]);

  useEffect(() => {
    //Need to sort out why it's not reading the activeClient.client_address only here
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => getProvLatLng(latLng))
      .catch((error) => console.error("Error", error));
  }, [address]);

  useEffect(() => {
    axios
      .get(`/api/field_options/?content_type=provider&ordering=order`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        setProvTypeOptions(response.data.results ?? []);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [setProvTypeOptions, accessToken]);

  return (
    <Modal
      open={openCustomProv}
      onClose={() => setOpenCustomProv(false)}
      title={"Add Out of Network Provider"}
      actions={[
        {
          label: "Submit Provider",
          variant: "outlined",
          onClick: handleNewProviderSubmit,
        },
      ]}
      maxWidth="xs"
    >
      <form className="flex flex-col gap-4">
        <FormField label="Out-of-Network Provider Name" required>
          <TextField
            required
            id="outlined-required"
            sx={inputStyles}
            value={outOfNetworkProv}
            onChange={(event) => {
              setOutOfNetworkProv(event.target.value);
            }}
          />
        </FormField>
        <FormField label="Address" required>
          <PlacesResponseListV2
            address={address}
            setAddress={setAddress}
            handlePlaceSelect={handlePlaceSelect}
            inputRequired={true}
          />
        </FormField>

        <FormField label="Out-of-Network Provider Phone" required>
          <TextField
            id="outlined-required"
            sx={inputStyles}
            value={outOfNetworkPhone}
            required
            onChange={(event) => {
              setOutOfNetworkPhone(event.target.value);
            }}
          />
        </FormField>
        <FormField label="Provider Type" required>
          <Select
            sx={selectStyles}
            value={outOfNetworkType}
            onChange={(e) => setOutOfNetworkType(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 190,
                },
              },
            }}
          >
            {provTypeOptions.map((opt) => (
              <MenuItem key={opt.order} value={opt.key}>
                {opt.key}
              </MenuItem>
            ))}
          </Select>
        </FormField>
      </form>
      <ResponseModal
        title="New Provider Added"
        description={`You have successfully added "${outOfNetworkProv}" to the
          system!`}
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title="Error Adding Provider"
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorMessage}
      />
    </Modal>
  );
}
