import {
  CONTENT_DOWNLOAD_CONFIRMATION,
  CURRENT_PAGE_SET,
  FETCH_DONE,
  FETCH_ERROR,
  FETCH_INPROGRESS,
  FETCH_IS_LOADING,
  FETCH_SUCCESS,
  ORDERING_BY_SET,
  ORDERING_DIR_SET,
  RESLIMIT_SET,
  RESULT_COUNT_SET,
  SET_COLUMNS,
  SET_FILTER_PARAM_LIST,
  SET_OFFSET,
  SET_REPORT_TITLE,
  SET_SEARCH,
  SET_SEARCH_DELAYED,
  SET_SELECTED_PROVIDER,
  SHOULD_FETCH_DATA,
  SHOW_CONTENT_SET,
  TOGGLE_DIALOG,
  TOGGLE_DIALOG_IS_LOADING,
} from "./actions";

export const initialState = {
  shouldFetchData: true,
  loading: false,
  success: null,
  error: null,
  errorArray: [],
  columns: {},
  data: null,
  search: "",
  delayedSearchString: "",
  resLimit: 1000,
  currentPage: 1,
  offset: "",
  resultCount: { total: 0, current: 0 },
  orderingBy: "",
  orderingDirection: "asc",
  filterString: "?limit=1000",
  selectedProvider: null,
  reportTitle: "",
  dialogIsLoading:true,
  showDialog: false,
  showContent: CONTENT_DOWNLOAD_CONFIRMATION,
};

export function reportsDetailReducer(state, action) {
  switch (action.type) {
    case SHOULD_FETCH_DATA: {
      return { ...state, shouldFetchData: action.payload };
    }
    case FETCH_IS_LOADING: {
      return { ...state, loading: action.payload };
    }
    case FETCH_INPROGRESS: {
      return { ...state, data: action.payload };
    }
    case FETCH_SUCCESS: {
      return { ...state, success: action.payload, error: null, errorArray: [] };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        success: null,
        error: true,
        errorArray: action.payload,
      };
    }
    case FETCH_DONE: {
      const isDone = action.payload === true ? false : true;
      return { ...state, loading: isDone };
    }
    case RESLIMIT_SET: {
      return { ...state, resLimit: action.payload };
    }
    case RESULT_COUNT_SET: {
      return { ...state, resultCount: action.payload };
    }
    case SET_OFFSET: {
      return { ...state, offset: action.payload };
    }
    case CURRENT_PAGE_SET: {
      return { ...state, currentPage: action.payload };
    }
    case ORDERING_BY_SET: {
      return { ...state, orderingBy: action.payload };
    }
    case ORDERING_DIR_SET: {
      return { ...state, orderingDirection: action.payload };
    }
    case SET_SEARCH: {
      return { ...state, search: action.payload };
    }
    case SET_SEARCH_DELAYED: {
      return { ...state, delayedSearchString: action.payload };
    }
    case SET_COLUMNS: {
      return { ...state, columns: action.payload };
    }
    case SET_FILTER_PARAM_LIST: {
      return { ...state, filterString: action.payload };
    }
    case SET_SELECTED_PROVIDER: {
      return { ...state, selectedProvider: action.payload };
    }
    case SET_REPORT_TITLE: {
      return { ...state, reportTitle: action.payload };
    }
    case TOGGLE_DIALOG: {
      return { ...state, showDialog: action.payload };
    }
    case TOGGLE_DIALOG_IS_LOADING: {
      return {...state, dialogIsLoading: action.payload}
    }
    case SHOW_CONTENT_SET: {
      return { ...state, showContent: action.payload };
    }
    default:
      return state;
  }
}
