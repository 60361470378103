import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Editor } from "@tinymce/tinymce-react";
import qs from "query-string";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import ModalButton from "../../../../global/ModalButton";
import ResponseModal from "../../../../global/ResponseModal";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";

export default function AssignAppt({ isModalVersion, handleModalClose }) {
  const {
    selectedProvider,
    setSelectedProvider,
    accessToken,
    userType,
    caseManagerPk,
  } = useContext(DataContext);
  const [cquery, setCQuery] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [apptNotes, setApptNotes] = useState("");
  const [dateTimeVal, setDateTimeValue] = useState("");
  const [errorArray, setErrorArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [value, setValue] = useState();
  const [search, setSearch] = useState("");
  const [scheduledApptStatus, setScheduledApptStatus] = useState("");
  const [providerNameList, setProviderNameList] = useState([]);
  const [paginationUrl, setPaginationUrl] = useState("");
  const [activeProvObj, setActiveProvObj] = useState();
  const [clientListResponse, setClientListResponse] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [activeClient, setActiveClient] = useState();
  const [selectedProviderType, setSelectedProviderType] = useState("");
  const [providerTypeList, setProviderTypeList] = useState([]);

  useEffect(() => {
    const fetchProviderTypes = async () => {
      try {
        const response = await axios.get(`/api/provider/${selectedProvider}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        });
        const results = response.data?.provider_types;
        setProviderTypeList(results);
      } catch (err) {
        console.error(err);
      }
    };
  
    if (selectedProvider) {
      fetchProviderTypes();
    }

  }, [selectedProvider, accessToken]);

  // Functions
  const handleProvider = (e) => {
    setProviderTypeList([])
    setSelectedProviderType("")
    setSelectedProvider(e.target.value);
  };

  const handleChange = (newValue) => {
    if (newValue === null || newValue === undefined) {
      setValue("");
    } else {
      setDateTimeValue(new Date(newValue).toISOString());
    }
  };

  const handleNewApptsSubmit = (e) => {
    let createNewAppt = {
      client: activeClient.pk,
      provider: selectedProvider,
      scheduled: dateTimeVal,
      status: scheduledApptStatus,
      provider_type: selectedProviderType,
      ...(apptNotes === "" ? {} : { notes: apptNotes }),
    };

    axios
      .post("/api/appointments/", createNewAppt, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 400) {
          setOpenSuccess(!openSuccess);
          setCQuery("");
        }
      })
      .catch(function (error) {
        if (error.response) {
          setErrorArray(error.response.data);
          setOpenError(!openError);
        }
      });
  };

  const populateClientInfo = (client) => {
    setActiveClient(client.client);
    setClientListResponse([]);
    setCQuery(client.client.name);
  };

  useEffect(() => {
    axios
      .get(`/api/provider/?managed_by=${caseManagerPk}&simplified=true?`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.results;
        let obj = {};

        for (const item in data) {
          obj[data[item].pk] = data[item].pk;
        }

        setActiveProvObj(obj);
      });
  }, [accessToken, caseManagerPk]);

  //  UseEffects
  useEffect(() => {
    setProviderNameList([]);
    if (activeClient) {
      axios
        .get(`/api/pro_cli_status/?client=${activeClient.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;

          if (userType === "Provider") {
            data = data.filter(
              (item) => item.provider.pk === activeProvObj[item.provider.pk]
            );
          }

          setProviderList(data);
        });
    }
        return () => {
          setSelectedProvider();
          setProviderTypeList([]);
        };
    // eslint-disable-next-line
  }, [activeClient, accessToken]);

  const sendRequest = () => {
    let querytest = qs.stringify(
      {
        search: search === "" ? null : search,
      },
      {
        skipNull: true,
      }
    );
    axios
      .get(
        `/api/case/?` + paginationUrl + querytest,

        {
          headers: { Authorization: `Token ${accessToken}` },
        }
      )
      .then(function (response) {
        setClientListResponse(response.data.results);
      })
      .catch(function () {});

    querytest = qs.parse(querytest);
  };

  useDebouncedEffect(
    () => sendRequest(),
    [search, paginationUrl, setPaginationUrl],
    250
  );

  useDebouncedEffect(
    () => {
      setPaginationUrl(``);
    },
    [search],
    250
  );

  const searchUpdate = (event) => {
    setSearch(event.target.value);
    setCQuery("");
  };
  useEffect(() => {
    let arrayVals = [];

    if (errorArray.client) {
      arrayVals.push(`Client: ${errorArray.client}`);
    }

    if (errorArray.provider) {
      arrayVals.push(`Provider: ${errorArray.provider}`);
    }

    if (errorArray.scheduled) {
      arrayVals.push(`scheduled: ${errorArray.scheduled}`);
    }

    if (errorArray.status) {
      arrayVals.push(`status: ${errorArray.status}`);
    }

    if (errorArray.notes) {
      arrayVals.push(`notes: ${errorArray.notes}`);
    }

    if (errorArray.provider_type) {
      arrayVals.push(`provider_type: ${errorArray.provider_type}`);
    }
    setErrorMessage(arrayVals);
    // eslint-disable-next-line
  }, [errorArray]);

  const modalStyles = isModalVersion
    ? { width: "100%", margin: "1rem 0 0 " }
    : {};

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="overflow-auto h-full bg-white p-2">
        <h1
          className="text-2xl text-center text-purple-600 uppercase font-bold w-full shadow-md px-4 rounded-lg py-2"
          style={
            isModalVersion
              ? { fontSize: "1.2rem", fontWeight: "bold", textAlign: "center" }
              : {}
          }
        >
          Create Appointment
        </h1>
        <form className="justify-center flex pt-6">
          <div className="flex sm:flex-row flex-col pt-5 sm:w-4/5 w-full mx-2">
            <div className="sm:w-4/5 w-full relative">
              <TextField
                required
                className="sm:mx-4 placeholder:text-center rounded-lg w-full sm:pr-4 my-4 sm:my-0"
                placeholder="Search Clients" //{`Search ${filter}`}
                value={!cquery ? search : cquery}
                style={modalStyles}
                onChange={(event) => searchUpdate(event)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={modalStyles}
                className="absolute z-10 mx-4 shadow-md w-full max-h-[300px] overflow-y-auto bg-white rounded-med max-w-[98%]"
              >
                {!search && cquery ? (
                  cquery
                ) : !search ? (
                  ""
                ) : clientListResponse.length !== 0 ? (
                  <div className="w-full h-full p-0 mb-2">
                    {clientListResponse.map((client, id) => (
                      <List key={id} className="p-0 my-1 w-full">
                        <div className="">
                          <ListItemButton
                            onClick={(e) => populateClientInfo(client)}
                            className="place-content-center overflow-x-hidden text-center border-solid border-gray-300 rounded-lg"
                          >
                            {client.client.name}
                          </ListItemButton>
                        </div>
                      </List>
                    ))}
                  </div>
                ) : clientListResponse.length === 0 && cquery ? (
                  ""
                ) : (
                  <h3 className="text-center bg-gray-200 rounded-lg uppercase">
                    No clients found
                  </h3>
                )}
              </div>
            </div>
          </div>
        </form>

        <form className="justify-center flex sm:pt-6">
          <div className="flex sm:pt-5 sm:w-4/5 w-full mx-2">
            <FormControl className="w-full" style={modalStyles}>
              <InputLabel id="Provider">Providers</InputLabel>
              <Select
                labelId="Provider"
                id="Provider"
                value={selectedProvider}
                label="Provider"
                onChange={(e) => handleProvider(e)}
              >
                {!providerNameList
                  ? ""
                  : providerList.map((prov, idx) => {
                      return (
                        <MenuItem value={prov.provider.pk} key={idx}>
                          {prov.provider.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </div>
        </form>

        {selectedProvider && (
          <form className="justify-center flex sm:pt-6">
            <div className="flex flex-col sm:pt-5 sm:w-4/5 w-full mx-2">
              <FormControl className="w-full" style={modalStyles}>
                <InputLabel id="ProviderType" className="w-full">
                  Provider Type
                </InputLabel>
                <Select
                  labelId="ProviderType"
                  id="ProviderType"
                  value={selectedProviderType}
                  label="Provider"
                  onChange={(event) =>
                    setSelectedProviderType(event.target.value)
                  }
                >
                  {providerTypeList?.map((protype) => {
                    return (
                      <MenuItem value={protype.id} key={protype.id}>
                        {protype.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </form>
        )}
        <form className="justify-center flex mt-6">
          <div
            className="flex sm:flex-row flex-col pt-5 sm:w-4/5 w-full mx-2 sm:mx-0"
            style={
              isModalVersion
                ? {
                    display: "flex",
                    margin: "1rem 0 0",
                    justifyContent: "space-between",
                  }
                : {}
            }
          >
            <DesktopDateTimePicker
              label="Appointment Date"
              value={value}
              className="w-full mr-2"
              onChange={handleChange}
              slotProps={{ textField: { variant: "outlined" } }}
              style={isModalVersion ? { width: "50%" } : {}}
            />
            <FormControl
              className="w-full flex sm:ml-2 sm:my-0 my-4"
              style={isModalVersion ? { width: "50%" } : {}}
            >
              <InputLabel id="appt-status">Appointment Status</InputLabel>
              <Select
                required
                className="w-full"
                labelId="appt-status"
                id="Provider-Status"
                value={scheduledApptStatus}
                label="Appt status"
                onChange={(event) => {
                  setScheduledApptStatus(event.target.value);
                }}
              >
                <MenuItem value={"Assigned"}>Assigned</MenuItem>
                <MenuItem value={"Overdue"}>Missed</MenuItem>
                <MenuItem value={"Done"}>Complete</MenuItem>
              </Select>
            </FormControl>
          </div>
        </form>

        <form className="justify-center flex sm:pt-6">
          <div className="flex-row sm:pt-5 sm:w-4/5 w-full mx-2 sm:mx-0">
            <h3
              className="mt-4 text-center sm:text-left"
              style={
                isModalVersion
                  ? {
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      textAlign: "center",
                      margin: "1rem 0",
                    }
                  : {}
              }
            >
              Appointment Notes
            </h3>
            <Editor
              textareaName="content"
              apiKey={process.env.REACT_APP_TINY_MCE_KEY}
              value={apptNotes}
              onEditorChange={(newText) => {
                setApptNotes(newText);
              }}
              init={{
                menubar: false,
                branding: false,
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
              }}
            />
          </div>
        </form>
        {isModalVersion ? (
          <div style={{ margin: "1rem 0" }}>
            <ModalButton
              baseColor="#1976d2"
              content="Add Appointment"
              action={handleNewApptsSubmit}
            />
          </div>
        ) : (
          <Button
            className="hover:text-white hover:bg-purple-600 text-purple-600 rounded-md flex justify-center border-solid border-purple-600 border w-fit mx-auto px-8 py-1 my-4"
            onClick={() => {
              handleNewApptsSubmit();
            }}
          >
            Add Appointment
          </Button>
        )}
        <ResponseModal
          title="Appointment Created"
          description="Appointment has been added to clients case"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
          handleCloseFunc={handleModalClose}
        />
        <ResponseModal
          title="Error Creating Appointment"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorMessage}
        />
      </div>
    </LocalizationProvider>
  );
}
