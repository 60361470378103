import { createContext, useState, useMemo, useEffect } from "react";
import Cookies from "js-cookie";
import { IS_EXPAND_FOLLOW_UP } from "../globalConstants";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [accessToken, getAccessToken] = useState(
    !Cookies.get("pnAccessToken")
      ? null
      : JSON.parse(Cookies.get("pnAccessToken"))
  );
  const [isLoading, setLoading] = useState(true);

  const [loggedInUser, setLoggedInUser] = useState();

  const [caseManagerPk, setCaseManagerPk] = useState(
    !Cookies.get("pnCmPk") ? null : JSON.parse(Cookies.get("pnCmPk"))
  );
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  //All used for the map/assign provider page
  const [selectedProvider, setSelectedProvider] = useState("");
  const [activeProvider, setActiveProvider] = useState();
  const [activeClientMap, setActiveClientMap] = useState(null);
  const [altAddress, getAltAddress] = useState(null);
  const [clientCoords, getClientCoords] = useState();
  const [nearestProviders, getNearestProviders] = useState();
  const [newNonNetProv, getNewNonNetProv] = useState();
  //End of  assign prov context

  const [trigger, setTrigger] = useState(false);
  const [casePk, getCasePk] = useState();
  const [caseManagerName, getCaseManagerName] = useState();
  const [searchProvPk, setSearchProvPk] = useState("");
  const [activeBills, setActiveBills] = useState();
  const [lostCodes, setLostCodes] = useState([]);
  const [activePath, setActivePath] = useState("search");
  const [q4LawfirmPath, setQ4LawfirmPath] = useState("liabilityRequested");
  const [tabTitle, setTabTitle] = useState();
  const [prevCL, setPrevCL] = useState([]);
  const [activeLawfirm, setActiveLawfirm] = useState("");
  const [lawFirmLink, setLawFirmLink] = useState("");
  const [userType, setUserType] = useState("");
  const [patchNotif, setPatchNotifs] = useState();
  const [refreshDialog, setRefreshDialog] = useState(false);
  const [currentPatchV, setCurrentPatchV] = useState();
  const [needsUpdate, setNeedsUpdate] = useState(false);
  const [selectedLead, setSelectedLead] = useState("");
  const [caseCountPk, setCaseCountPk] = useState("");
  //below state used for action items routing for admins
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedLawFirms, setSelectedLawFirms] = useState([]);
  const [activeLawFirms, setActiveLawFirms] = useState([]);
  const [selectedCases, setSelectedCases] = useState([]);
  const [activeCases, setActiveCases] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [activeProviders, setActiveProviders] = useState([]);
  const [userRoles, setUserRoles] = useState("");
  const [userPaths, setUserPaths] = useState([]);
  const [dashCompLimit, setDashCompLimit] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const [languageObj, setLanguageObj] = useState("");

  const [lawFirmFollowUps, setLawFirmFollowUps] = useState([]);
  const [clientFollowUps, setClientFollowUps] = useState([]);
  const [providerFollowUps, setProviderFollowUps] = useState([]);

  const [isFollowUpsExpanded, setIsFollowUpsExpanded] = useState(true);

  const [dashboardV, setDashboardV] = useState(
    !Cookies.get("dashVersion") ? "new" : Cookies.get("dashVersion")
  );

  useEffect(() => {
    const isExpandFollowUp = localStorage.getItem(IS_EXPAND_FOLLOW_UP);
    if (isExpandFollowUp) {
      try {
        const isExpandFollowUpNum = JSON.parse(isExpandFollowUp);
        if (!isNaN(isExpandFollowUpNum)) {
          setIsFollowUpsExpanded(!!Number(isExpandFollowUpNum));
        }
      } catch (error) {}
    }
  }, []);

  useEffect(
    () => {
      localStorage.setItem(IS_EXPAND_FOLLOW_UP, Number(isFollowUpsExpanded));
    },
    [isFollowUpsExpanded]
  );

  return (
    <DataContext.Provider
      value={useMemo(
        () => ({
          selectedClient,
          setSelectedClient,
          isLoggedIn,
          setIsLoggedIn,
          selectedProvider,
          setSelectedProvider,
          caseManagerPk,
          setCaseManagerPk,
          accessToken,
          getAccessToken,
          activeClientMap,
          setActiveClientMap,
          clientCoords,
          getClientCoords,
          activeProvider,
          setActiveProvider,
          nearestProviders,
          getNearestProviders,
          newNonNetProv,
          getNewNonNetProv,
          isLoading,
          setLoading,
          altAddress,
          getAltAddress,
          trigger,
          setTrigger,
          casePk,
          getCasePk,
          searchProvPk,
          setSearchProvPk,
          caseManagerName,
          getCaseManagerName,
          lostCodes,
          setLostCodes,
          activeBills,
          setActiveBills,
          activePath,
          setActivePath,
          q4LawfirmPath,
          setQ4LawfirmPath,
          tabTitle,
          setTabTitle,
          prevCL,
          setPrevCL,
          activeLawfirm,
          setActiveLawfirm,
          lawFirmLink,
          setLawFirmLink,
          userType,
          setUserType,
          patchNotif,
          setPatchNotifs,
          refreshDialog,
          setRefreshDialog,
          currentPatchV,
          setCurrentPatchV,
          needsUpdate,
          setNeedsUpdate,
          dashboardV,
          setDashboardV,
          selectedLead,
          setSelectedLead,
          caseCountPk,
          setCaseCountPk,
          activeUsers,
          setActiveUsers,
          selectedUsers,
          setSelectedUsers,
          selectedLawFirms,
          setSelectedLawFirms,
          activeLawFirms,
          setActiveLawFirms,
          selectedCases,
          setSelectedCases,
          activeCases,
          setActiveCases,
          selectedProviders,
          setSelectedProviders,
          activeProviders,
          setActiveProviders,
          userRoles,
          setUserRoles,
          userPaths,
          setUserPaths,
          dashCompLimit,
          setDashCompLimit,
          errorMessage,
          setErrorMessage,
          loggedInUser,
          setLoggedInUser,
          languageObj,
          setLanguageObj,
          lawFirmFollowUps,
          setLawFirmFollowUps,
          setIsFollowUpsExpanded,
          clientFollowUps,
          setClientFollowUps,
          providerFollowUps,
          setProviderFollowUps,
          isFollowUpsExpanded,
        }),
        [
          selectedClient,
          isLoggedIn,
          selectedProvider,
          caseManagerPk,
          accessToken,
          activeClientMap,
          clientCoords,
          activeProvider,
          nearestProviders,
          newNonNetProv,
          isLoading,
          altAddress,
          trigger,
          casePk,
          searchProvPk,
          caseManagerName,
          lostCodes,
          activeBills,
          activePath,
          q4LawfirmPath,
          tabTitle,
          prevCL,
          activeLawfirm,
          lawFirmLink,
          userType,
          patchNotif,
          refreshDialog,
          currentPatchV,
          needsUpdate,
          dashboardV,
          selectedLead,
          caseCountPk,
          activeUsers,
          selectedUsers,
          selectedLawFirms,
          activeLawFirms,
          selectedCases,
          activeCases,
          selectedProviders,
          activeProviders,
          userRoles,
          userPaths,
          dashCompLimit,
          errorMessage,
          loggedInUser,
          languageObj,
          lawFirmFollowUps,
          isFollowUpsExpanded,
          clientFollowUps,
          providerFollowUps,
        ]
      )}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
