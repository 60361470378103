import { FileOpen } from "@mui/icons-material";
import PropTypes from 'prop-types';
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import ActionButton from "../ui/ActionButton";

const OpenDocumentList = ({ permission, setViewDocuments }) => {
  const openDocListModal = () => {
    setViewDocuments(true);
  };
  return (
    <PermissionWrapper permission={permission}>
      <ActionButton
        title={"Open Document List"}
        icon={<FileOpen fontSize='18'/>}
        action={openDocListModal}
      />
    </PermissionWrapper>
  );
};

export default OpenDocumentList;

OpenDocumentList.propTypes = {
  permission: PropTypes.any,
  setViewDocuments: PropTypes.func
}
