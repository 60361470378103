function isEmpty(obj) {
  if (obj) {
    if (Object.keys(obj).length === 0) {
      return true;
    } else return false;
  }
  return true;
}
export function GenerateQueryParameterString(obj) {
  if (isEmpty(obj)) {
    return "";
  }
  let arr = Object.keys(obj)
    .filter((item) => {
      let keyStr = obj[item].toString();
      return keyStr.trim() !== "" && keyStr;
    })
    .map((item) => {
      let keyStr = item.toString();
      return keyStr + "=" + obj[item];
    });

  return arr.length > 0 ? "?" + arr.join("&") : "";
}
