import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import React, { useMemo } from "react";

export default function AccordionSelectForm({
  title,
  val,
  setVal,
  resetPagination,
  choicesArr,
}) {

  const value = useMemo(() => {
    if (title.trim() === "Liability Request") {
      return choicesArr.find(obj => obj.key === parseInt(val))?.label;
    } else {
      return val;
    }
  }, [choicesArr, title, val])

  return (
    <div>
      {" "}
      <Accordion className="w-full">
        <AccordionSummary
          className="text-sm"
          expandIcon={
            <div className="flex items-center">
              <ExpandMore />
            </div>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>{title}</div>
          <div>
            {!val ? (
              ""
            ) : (
              <div>
                &mdash;
                <span className="text-xs text-sky-600">{value}</span>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              className="text-sm"
              defaultValue={null}
              name="radio-buttons-group"
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
                resetPagination();
              }}
            >
              {choicesArr.map((choice, idx) => (
                <FormControlLabel
                  key={idx}
                  value={choice.key}
                  control={<Radio />}
                  label={choice.label}
                  className="text-sm"
                />
              ))}
              <FormControlLabel
                className="hidden text-sm"
                value={""}
                control={<Radio />}
                label="hidden"
              />
            </RadioGroup>
          </FormControl>
          <Button
            onClick={() => {
              setVal("");
              resetPagination();
            }}
            variant="outlined"
            className="text-sm text-red-600 border-red-600 hover:bg-red-600 hover:text-white"
          >
            Clear Filter
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
