import { Language } from "@mui/icons-material";
import PropTypes from 'prop-types';
import BaseField from "../ui/BaseField";
import ValueWithFallback from "../ui/ValueWithFallback";

const PreferredLanguage = ({ fieldName, value, flag, clock }) => {
  return (
    <BaseField
      id="SB-Language"
      testId="SB-Language"
      fieldName={fieldName}
      flag={flag}
      clock={clock}
    >
      <div className='h-[24px]'>
        <Language fontSize="inherit" />
        {ValueWithFallback(value)}
      </div>
    </BaseField>
  );
};

export default PreferredLanguage;


PreferredLanguage.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  flag: PropTypes.any,
  clock: PropTypes.any,
}
