import { RequestQuote } from "@mui/icons-material";
import PropTypes from 'prop-types';
import { useNavigate, useParams } from "react-router-dom";
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import ActionButton from "../ui/ActionButton";

const ViewSettlementDetail = ({ permission }) => {
  const params = useParams();
  const navigate = useNavigate();

  const navigateToDetail = () => navigate(`/settlement/${params.id}`);

  return (
    <PermissionWrapper permission={permission}>
      <ActionButton
        title={"View Settlement Detail"}
        icon={<RequestQuote fontSize='18'/>}
        action={navigateToDetail}
      />
    </PermissionWrapper>
  );
};

export default ViewSettlementDetail;

ViewSettlementDetail.propTypes = {
  permission: PropTypes.any,
}
