import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../context/DataContext";
import axios from "../../../../api/axios";
import ProfileInfo from "./ProfileInfo";
import AccountSettings from "./AccountSettings";
import OrgPermissions from "./OrgPermissions";
import LoadingIndicator from "../../../../global/LoadingIndicator";
import UserOptions from "./UserOptions/UserOptions";

export default function MyAccount() {
  const { accessToken, loggedInUser } = useContext(DataContext);
  const [trigger, setTrigger] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [managedLocations, setManagedLocations] = useState([]);

  useEffect(() => {
    if (
      loggedInUser.entity &&
      loggedInUser.entity.entity_type_label === "Provider"
    ) {
      axios
        .get(`/api/provider/?limit=100&managed_by=${loggedInUser.pk}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          setManagedLocations(response.data.results);
        });
    }
  }, [accessToken, loggedInUser, setManagedLocations]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/users/me/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data;
        setUserInfo(data);
        setLoading(false);
      })
      .catch((_error) => {
        setLoading(false);
        setErrorMessage("There was an error loading this content");
      });
  }, [accessToken, setUserInfo, trigger]);

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator isLoading={loading} />;
    } else if (!userInfo && errorMessage) {
      return <p>{errorMessage}</p>;
    } else {
      return (
        <div className="w-full relative h-full">
          <div className="xl:flex w-full h-full ">
            <ProfileInfo
              userInfo={userInfo}
              trigger={trigger}
              setTrigger={setTrigger}
              myAccount={true}
              managedLocations={managedLocations}
            />
            <div className="xl:w-8/12 xl:h-full overflow-y-auto">
              <AccountSettings
                userInfo={userInfo}
                trigger={trigger}
                setTrigger={setTrigger}
                myAccount={true}
              />
              <OrgPermissions
                userInfo={userInfo}
                trigger={trigger}
                setTrigger={setTrigger}
                myAccount={true}
                managedLocations={managedLocations}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="h-full">
      {renderContent()}
    </div>
  );
}
