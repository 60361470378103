import TabPanel from "../../../ClientDetail/TabClientNotesCallLog/TabPanel";

export default function TabBody({ tabList, tabValue }) {
  return (
    <div className="max-h-[30vh] min-h-[20vh] overflow-auto">
      {tabList.map((tab, index) => (
        <TabPanel value={tabValue} index={index} className="h-full">
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
}