import React, { useContext, useEffect, useState } from "react";
import AccordionShell from "../../../../../../../global/AccordionShell";
import DataContext from "../../../../../../../../context/DataContext";
import StatusSelect from "../ProviderListItem/StatusSelect";
import { IconButton } from "@mui/material";
import { Delete, ListAltOutlined } from "@mui/icons-material";
import ModalShell from "../../../../../../../global/ModalShell";
import DeleteProviderv2 from "../../DeleteProviderv2";
import ProviderDetailv2 from "../../../../../../../global/ProviderDetailModal/ProviderDetailv2";
import EditTotalInput from "../EditTotalInput";
import { MEDICAL_RECORD_NUMBER_KEY_VAL, TOTAL_INPUTS } from "../contants";
import PropTypes from 'prop-types';

export default function ProviderListItemMobile({
  prov,
  activeProvObj,
  proCliTrigger,
  setProCliTrigger,
  docTypeOptions,
  docTypeOptionsObj,
  caseInfo,
}) {
  const { userRoles } = useContext(DataContext);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openProvDetail, setOpenProvDetail] = useState(false);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    setProvider(prov);
  }, [prov]);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  return (
    <div className="lg:hidden">
      <AccordionShell
        title={
          <div>
            {prov.provider.name} -{" "}
            {prov?.provider?.provider_types?.map((type, idx, arr) => (
              <span className="text-gray-400 text-xs" key={type.id}>
                {type.name}
                {idx < arr.length - 1 && ", "}
              </span>
            )) || []}
          </div>
        }
        customAdornment={!prov.provider.in_network ? "red" : false}
      >
        <div className="border-purple-600 rounded-md border p-2">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mx-2 flex flex-col gap-2">
              {prov.can_view_balance_and_mrn ? TOTAL_INPUTS.map(key => (
                <EditTotalInput
                  key={key.keyVal}
                  title={key.title}
                  type={key.type}
                  keyVal={key.keyVal}
                  tooltipEnabled={key.tooltipEnabled}
                  provider={provider}
                  setProvider={setProvider}
                  noDataPlaceholder={key.noDataPlaceholder}
                  isMedicalRecordValidate={key.keyVal === MEDICAL_RECORD_NUMBER_KEY_VAL}
                  />
              )) : (
                <div className="grid justify-around">
                  <span>-</span>
                </div>
              )}
            </div>
            <div className="md:w-1/2 mx-2">
              <div className="flex justify-center">
                {" "}
                <IconButton
                  onClick={() => setOpenProvDetail(true)}
                  className="text-purple-600"
                >
                  <ListAltOutlined className="text-[18px]" />
                </IconButton>{" "}
                {userRoles.permissions.includes(
                  "provider.delete_providerclientstatus"
                ) ? (
                  <IconButton
                    onClick={() => handleOpenDeleteModal()}
                    className="text-red-600"
                  >
                    <Delete className="text-[18px]" />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mx-2">
              <StatusSelect
                provider={prov}
                activeProvObj={activeProvObj}
                proCliTrigger={proCliTrigger}
                setProCliTrigger={setProCliTrigger}
                docTypeOptions={docTypeOptions}
                docTypeOptionsObj={docTypeOptionsObj}
              />
            </div>
            <div className="md:w-1/2 mx-2 text-center">
              <span className="block">
                <strong>Created by:</strong> {prov.created_by_name}
              </span>
              <span className="block">
                <strong>Created at:</strong>&nbsp;
                {new Date(prov.created_at).toLocaleString("EN-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
            <div className="md:w-1/2 mx-2 text-center">
              <span className="block">
                <strong>First Appointment:</strong>&nbsp;
                {new Date(prov.first_appointment_date).toLocaleString("EN-US", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          </div>
        </div>
      </AccordionShell>
      {openDeleteModal ? (
        <ModalShell
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={"Delete Provider"}
          color={"#d32f2f"}
          customWidth={"md"}
        >
          <DeleteProviderv2
            providerDetail={prov}
            setDeleteFormDisplay={setOpenDeleteModal}
            trigger={proCliTrigger}
            setTrigger={setProCliTrigger}
            caseInfo={caseInfo}
          />
        </ModalShell>
      ) : (
        ""
      )}
      {openProvDetail ? (
        <ProviderDetailv2
          providerDetail={prov.provider}
          open={openProvDetail}
          setOpen={setOpenProvDetail}
        />
      ) : (
        ""
      )}
    </div>
  );
}

ProviderListItemMobile.propTypes = {
  prov: PropTypes.object,
};
