import { useContext, useEffect, useState } from "react";
import { Chip, CircularProgress, List, ListItem } from "@mui/material";
import PropTypes from "prop-types";

import axios from "../../../../../../api/axios";
import DataContext from "../../../../../../../context/DataContext";

export const OrganizationAndPermissions = (props) => {
  const { userInfo, trigger } = props;
  const { accessToken, userRoles } = useContext(DataContext);

  const [managedBy, setManagedBy] = useState();
  const [managingUsers, setManagingUsers] = useState();
  const [fetchManagedByLoading, setFetchManagedByLoading] = useState(true);
  const [fetchManagingUserLoading, setFetchManagingUserLoading] =
    useState(true);

  useEffect(() => {
    if (!userInfo?.pk) return;
    setFetchManagedByLoading(true);

    axios
      .get(`/api/user_manager/?user=${userInfo.pk}`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.results;
        if (data?.[0]?.managed_by?.is_active) setManagedBy(data[0]);
        setFetchManagedByLoading(false);
      })
      .catch(() => {
        setFetchManagedByLoading(false);
      });
  }, [accessToken, setManagedBy, trigger, userInfo, userRoles?.permissions]);

  useEffect(() => {
    if (!userInfo?.pk) return;
    setFetchManagingUserLoading(true);

    axios
      .get(`/api/user_manager/?managed_by=${userInfo.pk}`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.results?.filter(
          (managedBy) => !!managedBy?.user?.is_active
        );
        setManagingUsers(data);
        setFetchManagingUserLoading(false);
      })
      .catch(() => {
        setFetchManagingUserLoading(false);
      });
  }, [accessToken, setManagingUsers, trigger, userInfo, userRoles?.permissions]);

  return (
    <div className="border-t border-gray py-2 relative">
      <div className="flex justify-between items-center pb-2">
        <h3 className="font-bold text-lg">Organization & Permissions</h3>
      </div>
      <List className="p-0">
        <ListItem className="gap-2 pr-0">
          <span className="underline whitespace-nowrap flex-[0_0_92px]">
            Managed By:
          </span>
          {fetchManagedByLoading ? (
            <CircularProgress size="1.5rem" />
          ) : (
            <div
              className="flex-[1_1_auto] overflow-hidden"
              data-testid="fetch-managed-by-chip"
            >
              {!!managedBy && (
                <Chip
                  label={managedBy.managed_by?.name}
                  color="primary"
                  size="small"
                  className="px-2"
                />
              )}
            </div>
          )}
        </ListItem>
        <ListItem className="gap-2 py-0 pr-0">
          <span className="underline whitespace-nowrap flex-[0_0_124px]">
            Managing Users:
          </span>
          {fetchManagingUserLoading ? (
            <CircularProgress size="1.5rem" />
          ) : (
            <div
              className="overflow-x-auto flex-[1_1_auto] flex gap-2 py-2"
              data-testid="fetch-managing-users-chips"
            >
              {!!managingUsers?.length &&
                managingUsers.map(
                  (user) =>
                    !!user && (
                      <Chip
                        key={user.pk}
                        label={user.user?.name}
                        color="primary"
                        size="small"
                        className="max-w-fit px-2"
                      />
                    )
                )}
            </div>
          )}
        </ListItem>
      </List>
    </div>
  );
};

OrganizationAndPermissions.propTypes = {
  userInfo: PropTypes.object,
  trigger: PropTypes.bool,
};
