import STRINGS from "../utils/strings";

const NoAvailableFollowUps = () => {
  return (
    <div className="followup-item text-center py-2 border-t-[2px] border-solid border-slate-500 bg-slate-100 rounded-md">
      <span className="text-sm text-slate-800 py-1 px-3 bg-white border-white border-solid rounded-md">
        {STRINGS.NO_FOLLOW_UPS_AVAILABLE}
      </span>
    </div>
  );
};
export default NoAvailableFollowUps;
