import { MonetizationOn } from "@mui/icons-material";
import PropTypes from 'prop-types';
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import ActionButton from "../ui/ActionButton";

const AddLiensBalances =  ({ permission, setOpenExpense}) => {
  const openExpenseView = () => {
    setOpenExpense(true);
  }

  return (
    <PermissionWrapper permission={permission}>
      <ActionButton
        title={"Add Lien/Balance"}
        icon={<MonetizationOn fontSize='18'/>}
        action={openExpenseView}
      />
    </PermissionWrapper>
  );
};



export default AddLiensBalances;

AddLiensBalances.propTypes = {
  permission: PropTypes.any,
  setOpenExpense:PropTypes.func
}
