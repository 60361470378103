import React from "react";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const FormatCurrency = (value) => {
  if (formatter.format(value) === "$NaN") return value;
  return formatter.format(value);
};

const CurrencyType = ({ amount }) => {
  return <div>{FormatCurrency(amount)}</div>;
};

export default CurrencyType;
