import { useContext, useEffect, useState } from "react";
import { Cancel, Check, Close, Edit } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import DataContext from "../../../../../../../context/DataContext";
import { currencyFormatter, truncate } from "../../../../../../global/helpers";
import axios from "../../../../../../api/axios";
import HoverTooltipWithApi from "./HoverTooltipWithApiCalling";


export default function EditTotalInput({
  title,
  type,
  keyVal,
  provider,
  setProvider,
  noDataPlaceholder = "Add Value",
  isMedicalRecordValidate,
  tooltipEnabled,
}) {
  const { accessToken , trigger, setTrigger } = useContext(DataContext);

  const [editing, setEditing] = useState(false);
  const [val, setVal] = useState("");
  const [hoverActive, setHoverActive] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [responseBreakdown, setResponseBreakdown] = useState("");


  useEffect(() => {
    setVal(provider?.[keyVal]);
  }, [keyVal, provider])

  const handleApiError = (error) => {
    const errorData = error?.response?.data;
    const msg = errorData ? Object.values(errorData)?.[0] : "Unspecified Error. Please try again.";
    setResponseBreakdown(msg);
    setResponseSuccess(false);
  };

  const handleApiSuccess = (response, valueName, key) => {
    setResponseBreakdown(`${valueName} was successfully updated`);
    setResponseSuccess(true);
    setEditing(false);
    setProvider(prev => {
      const newData = { ...prev };
      newData[key] = response.data?.[key];

      return newData;
    })
    setTimeout(() => {
      setResponseSuccess(null);
      setResponseBreakdown("");
      setTrigger(!trigger)
    }, 5000);
    return response;
  };

  const submitData = (title) => {
    // case keyVal is "current_balance" and current value is empty string
    // otherwise using current value
    const postVal = val === "" && keyVal === "current_balance" ? null : val;
    const postData = { [keyVal]: postVal };

    axios
      .patch(`/api/pro_cli_status/${provider?.pk}/`, postData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        handleApiSuccess(response, title, keyVal);
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const cancelEdit = () => {
    setVal(provider?.[keyVal]);
    setEditing(false);
    setResponseBreakdown("");
    setResponseSuccess(null);
  };

  const medicalRecordValidate = (e) => {
    const value = e.target.value;
    // Remove special characters
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
    // Limit to 40 characters
    const trimmedValue = sanitizedValue.slice(0, 40);
    setVal(trimmedValue);
  }

  const typeDisplayFormat = (value) => {
    if (value === "" || value === null) {
      return noDataPlaceholder;
    } else if (type === "number") {
      return currencyFormatter.format(value);
    } else {
      return truncate(value, 30);
    }
  };

  const generateInput = () => {
    switch (type) {
      case "text":
        return (
          <TextField
            className="w-full text-sm"
            size="small"
            label={title}
            onChange={(e) => isMedicalRecordValidate
              ? medicalRecordValidate(e)
              : setVal(e.target.value)}
            value={val ?? ""}
            error={responseSuccess !== null && !responseSuccess}
          />
        );

      case "number":
        return (
          <TextField
            size="small"
            className="w-full text-sm"
            type="number"
            label={title}
            onChange={(e) => setVal(e.target.value)}
            value={val ?? ""}
            error={responseSuccess !== null && !responseSuccess}
            InputProps={{
              startAdornment: <InputAdornment className="text-sm" position="start">$</InputAdornment>,
            }}
          />
        );

      default:
        return null;
    }
  }

  const generateResponseMsg = () => {
    // If responseSuccess is null, no message will be displayed
    if (responseSuccess === null) return null;

    return !responseSuccess ? (
      <p className="text-red-600 text-xs">
        <Cancel className="text-red-600 text-base" />
        {responseBreakdown ? <span className="ml-1">{responseBreakdown}</span> : null}
      </p>
    ) : (
      <p className="text-xs">
        {responseBreakdown ? <span className="mr-1">{responseBreakdown}</span> : null}
        <Check className="text-green-600" />
      </p>
    )
  }

  return (
    <div className="text-center">
      {editing ? (
        <div className="flex items-center w-full justify-between">
          <div className="w-[80%] self-center">
            {generateInput()}
          </div>
          {!responseSuccess ? (
            <div className="flex flex-wrap justify-end w-[20%]">
              <Tooltip title="Submit">
                <IconButton
                  className="text-green-600"
                  onClick={() => submitData(title)}
                  size="small"
                >
                  <Check className="text-base" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  className="text-red-600"
                  onClick={() => cancelEdit()}
                  size="small"
                >
                  <Close className="text-base" />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <h2 className="text-sm text-gray-400 flex justify-center gap-1 items-center">
            <p>{title}:</p>
            {tooltipEnabled && <HoverTooltipWithApi provider={provider} />}
          </h2>
          {provider?.can_edit_balance_and_mrn ? (
            <Tooltip title="Click to edit">
              <span>
                <Button
                  onClick={() => {
                    setEditing(true);
                    setHoverActive(false);
                  }}
                  className="text-black hover:bg-gray-200 break-all normal-case w-full relative"
                  onMouseEnter={() => setHoverActive(true)}
                  onMouseLeave={() => setHoverActive(false)}
                  data-testid="edit-button"
                >
                  <span className="px-4">{typeDisplayFormat(provider?.[keyVal])}</span>
                  <span className="absolute flex-[0_0_16px] right-2 top-[6px]">
                    {hoverActive ? <Edit className="text-gray-400 text-base" /> : ""}
                  </span>
                </Button>
              </span>
            </Tooltip>
          ) : (
            <div className="text-black break-all normal-case justify-between w-full">
              {typeDisplayFormat(provider?.[keyVal])}
            </div>
          )}
        </div>
      )}
      {generateResponseMsg()}
    </div>
  )
}

EditTotalInput.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  keyVal: PropTypes.string,
  provider: PropTypes.object,
  setProvider: PropTypes.func,
  noDataPlaceholder: PropTypes.string,
  isMedicalRecordValidate: PropTypes.bool,
  tooltipEnabled: PropTypes.bool,
};
