import { AddCircleOutline, InfoRounded } from "@mui/icons-material";
import { useContext } from "react";
import { FollowUpDispatchContext } from "../../context/FollowUpContext";
import {
  CONTENT_ADD_FOLLOWUP,
  CONTENT_FOLLOWUPLIST,
  showContent,
  toggleDialog,
} from "../../state/actions";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { TooltipStyling } from "./InfoTooltip/TooltipStyling";
import STRINGS from "../utils/strings";
import DataContext from "../../../../../../../../../context/DataContext";
import { getDisplayFollowUp } from "../utils/getDisplayFollowUp";
import { ACTION_TYPE } from "../types/actionTypes";
import PropTypes from "prop-types";

const { CLIENT_FOLLOW_UP, LAW_FIRM_FOLLOW_UP, PROVIDER_FOLLOW_UP } =
  ACTION_TYPE;

const Header = ({ icon, title, actionType }) => {
  const dispatch = useContext(FollowUpDispatchContext);

  const { isFollowUpsExpanded, lawFirmFollowUps, clientFollowUps, providerFollowUps } =
    useContext(DataContext);

  const displayFollowUp = getDisplayFollowUp([
    ...lawFirmFollowUps,
    ...clientFollowUps,
    ...providerFollowUps,
  ], isFollowUpsExpanded);

  const HtmlInfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  const showDialog = (value) => {
    showContent(dispatch, value);
    toggleDialog(dispatch, true);
  };

  const showListBtn = displayFollowUp
    ? actionType === displayFollowUp?.action_type
    : actionType === CLIENT_FOLLOW_UP;

  const showAddButton =
    actionType === PROVIDER_FOLLOW_UP ||
    (actionType === CLIENT_FOLLOW_UP && !clientFollowUps?.length) ||
    (actionType === LAW_FIRM_FOLLOW_UP && !lawFirmFollowUps?.length);

  return (
    <div className="flex">
      <div className="w-[90%]">
        {icon} {title}
      </div>
      <div className="flex flex-row w-[10%]">
        <HtmlInfoTooltip title={"Add Follow Up"}>
          <>
            {showAddButton && (
              <AddCircleOutline
                className="text-green-500 hover:text-green-800"
                onClick={() => showDialog(CONTENT_ADD_FOLLOWUP)}
              />
            )}
          </>
        </HtmlInfoTooltip>
        {showListBtn && (
          <HtmlInfoTooltip title={`Display ${STRINGS.FOLLOW_UP_LIST_TITLE}`}>
            <InfoRounded
              className="text-slate-900 hover:text-slate-500"
              onClick={() => showDialog(CONTENT_FOLLOWUPLIST)}
            />
          </HtmlInfoTooltip>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  actionType: PropTypes.string,
};

export default Header;
