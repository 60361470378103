import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { ReportsDetailDispatchContext } from "../../context/reportsDetailProvider";
import { setSelectedProvider } from "../../state/actions";
import { STRINGS } from "../../utils/strings";

const ProviderType = ({ providerId, providerName }) => {
  const dispatch = useContext(ReportsDetailDispatchContext);

  return (
    <Tooltip title={STRINGS.TOOLTIP_PROVIDER_LINK}>
      <Link
        onClick={() => setSelectedProvider(dispatch, { pk: providerId })}
        className="text-left underline text-purple-800 hover:text-black"
      >
        {providerName}
      </Link>
    </Tooltip>
  );
};

export default ProviderType;
