import { useContext, useEffect, useMemo, useState } from "react";
import { Person } from "@mui/icons-material";
import PropTypes from "prop-types";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { styled, Tooltip, tooltipClasses } from "@mui/material";

import NameModel from "./NameModel";
import BaseField from "../../ui/BaseField";
import ValueWithFallback from "../../ui/ValueWithFallback";
import { TooltipStyling } from "../../utils/TooltipStyling";
import axios from "../../../../../../../../api/axios";
import DataContext from "../../../../../../../../../context/DataContext";
import CaseSummaryContext from "../../../../CaseSummaryV2/context/CaseSummaryContext";

export default function Name({ fieldName, prefix, name, flag, clock }) {
  const { accessToken, userRoles } = useContext(DataContext);
  const { caseDetail } = useContext(CaseSummaryContext);

  const [campaignData, setCampaignData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Memoized permission check to avoid unnecessary re-renders
  const hasCampaignViewPermission = useMemo(
    () => userRoles?.permissions.includes("leads.view_campaign"),
    [userRoles?.permissions]
  );

  useEffect(() => {
    // Start loading at the beginning
    setIsLoading(true);

    if (!hasCampaignViewPermission || !caseDetail.campaign) {
      setIsLoading(false);
      return;
    }

    axios
      .get(`/api/leads/campaigns/${caseDetail.campaign}/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((resp) => {
        setCampaignData(resp.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    accessToken,
    caseDetail.campaign,
    hasCampaignViewPermission,
    userRoles.permissions,
  ]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: TooltipStyling,
  }));

  const generateTooltipTitle = () => {
    if (isLoading) return "Loading...";
    if (campaignData?.name) return `Campaign: ${campaignData?.name}`;
    return "No Campaign";
  };

  return (
    <BaseField
      id="SB-Client-Name"
      testId="SB-Client-Name"
      fieldName={fieldName}
      flag={flag}
      clock={clock}
      contentClassName="flex flex-nowrap w-full"
    >
      <div className="h-[23px] flex-[1_1_auto]">
        <Person fontSize="inherit" />
        {ValueWithFallback(NameModel(prefix, name))}
      </div>
      {hasCampaignViewPermission && (
        <HtmlTooltip
          title={<div className="w-fit">{generateTooltipTitle()}</div>}
          placement="right"
        >
          <div className="flex-[0_0_24px]" data-testid="campaign-icon">
            <FingerprintIcon sx={{ color: "#800080" }} />
          </div>
        </HtmlTooltip>
      )}
    </BaseField>
  );
}

Name.propTypes = {
  fieldName: PropTypes.string,
  prefix: PropTypes.any,
  name: PropTypes.string,
  flag: PropTypes.any,
  clock: PropTypes.any,
};
