import React, { useContext } from "react";
import TabPanel from "./components/TabPanel";
import DataContext from "../../../../../../context/DataContext";
import GeneralInfoContent from "./components/tabcontent/GeneralInfoContent";

function UserOptions({ userInfo, trigger, setTrigger, myAccount }) {
  const { userRoles } = useContext(DataContext);

  const generateTabList = () => {
    let tabList = [];
    tabList.push(
      {
        name: "General Info",
        component: (
          <GeneralInfoContent
            userInfo={userInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            myAccount={myAccount}
          />
        ),
      },
      {
        name: "Auto Assignment",
        component: <></>,
      },
      {
        name: "Organization",
        component: <></>,
      },
      {
        name: "Permissions",
        component: <></>,
      },
      {
        name: "Settings",
        component: <></>,
      }
    );

    return tabList;
  };

  const tabs = !userRoles.permissions ? [] : generateTabList();

  return (
    <div className="bg-white shadow-lg p-4 h-fit xl:mr-6 mt-6 rounded-sm">
      <TabPanel tabList={tabs} />
    </div>
  );
}
 
export default UserOptions;
