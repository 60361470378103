import React from "react";
import CountColumn from "./CountColumn";

export default function ActionItemCounts() {
  return (
    <div className="bg-white p-2">
      <h1 className="text-[1.5rem] text-purple-600 font-bold text-center mb-4">
        Action Item Counts
      </h1>
      <div className="flex">
        <CountColumn
          title="Law Firm"
          endpoint="law_firm"
          searchKey="on_firm__name__icontains"
        />
        <CountColumn
          title="Provider"
          endpoint="provider"
          searchKey="on_provider__name__icontains"
        />
        <CountColumn
          title="Cases"
          endpoint="cases"
          searchKey="on_case__combo_name__icontains"
        />
        <CountColumn
          title="Users"
          endpoint="users"
          searchKey="acting_user__name__icontains"
        />
      </div>
    </div>
  );
}
