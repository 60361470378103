import {
  AddBusinessOutlined,
  CalendarMonthOutlined,
  Close,
  ContactPhoneOutlined,
  DashboardOutlined,
  Group,
  Info,
  InsertChartOutlined,
  LogoutOutlined,
  MenuOpenOutlined,
  Person,
  PersonAddAltOutlined,
  PersonSearchOutlined,
  SettingsAccessibilityOutlined,
  TextSnippetOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, IconButton, List, ListItem, Tooltip } from "@mui/material";
import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import DataContext from "../../context/DataContext";
import PatchInfo from "../global/PatchInfo";
import axios from "../api/axios";
import useForceLogout from "../hooks/forceLogout";
import CustomPopUpMenu from "../global/CustomPopUpMenu";
import PopupState, { bindHover } from "material-ui-popup-state";

export default function SideBar() {
  const { setAuth } = useContext(AuthContext);
  const {
    getAccessToken,
    accessToken,
    setCaseManagerPk,
    setSelectedProvider,
    selectedClient,
    setSelectedClient,
    setUserType,
    userRoles,
    setDashCompLimit,
    setErrorMessage,
    loggedInUser,
  } = useContext(DataContext);
  const [openPatchInfo, setOpenPatchInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useForceLogout(!userRoles);

  const generateMenuData = () => {
    let menuArr = [];
    if (!userRoles.permissions) {
      menuArr.push(
        {
          title: "Reports",
          url: "reports",
          icon: <TextSnippetOutlined />,
          order: 8,
        },
      );
    } else {
      if (userRoles.permissions.includes("casemanager.view_case")) {
        menuArr.push(
          {
            title: "DASHBOARD",
            url: "dashboard",
            icon: <DashboardOutlined />,
            order: 1,
          },
          {
            title: "SEARCH CLIENTS",
            url: "search",
            icon: <PersonSearchOutlined />,
            order: 2,
          },
          {
            title: "CLIENT DETAIL",
            url: `client-detail/${selectedClient || null}`,
            icon: <SettingsAccessibilityOutlined />,
            order: 7,
          }, //Folder
          {
            title: "Reports",
            url: "reports",
            icon: <TextSnippetOutlined />,
            order: 8,
          },
        );
      }
      if (userRoles.permissions.includes("casemanager.add_case")) {
        menuArr.push({
          title: "ADD CLIENT",
          url: "add-client",
          icon: <PersonAddAltOutlined />,
          order: 3,
        });
      }
      if (userRoles.permissions.includes("provider.add_providerclientstatus")) {
        menuArr.push({
          title: "PROVIDER MAP",
          url: "provider-map",
          icon: <AddBusinessOutlined />,
          order: 4,
        });
      }

      if (
        userRoles.permissions.includes("provider.view_appointment") ||
        userRoles.permissions.includes(
          "provider.view_appointments_related_to_treating_clients"
        ) ||
        userRoles.permissions.includes(
          "provider.view_appointments_related_to_assigned_clients"
        )
      ) {
        menuArr.push(
          {
            title: "APPT CALENDAR",
            url: "appointment-calendar",
            icon: <CalendarMonthOutlined />,
            order: 5,
          },
          {
            title: "ANALYTICS",
            url: "analytics",
            icon: <InsertChartOutlined />,
            order: 6,
          }
        );
      }
    }
    return menuArr;
  };

  const Menus = !userRoles.permissions ? [] : generateMenuData();

  const generateUserMenuData = () => {
    let userMenuArr = [];

    if (userRoles.permissions.includes("users.add_user")) {
      userMenuArr.push({
        icon: <PersonAddAltOutlined className="text-black" />,
        title: "Add User",
        url: "/add-application-user",
      });
    }
    if (userRoles.permissions.includes("users.view_user")) {
      userMenuArr.push(
        {
          icon: <Group className="text-black" />,
          title: "View/Manage Users",
          url: "/user-profiles",
        },
        {
          icon: <ContactPhoneOutlined className="text-black" />,
          title: "Contacts",
          url: "/contact",
        }
      );
    }
    if (userRoles.permissions.includes("lawyer.add_lawfirm")) {
      userMenuArr.push({
        icon: <AddBusinessOutlined className="text-black" />,
        title: "Add Law Firm",
        url: "/create-law-firm",
      });
    }
    return userMenuArr;
  };

  const logout = () => {
    axios
      .post("/auth/logout", null, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(function (response) {
        if (response.status < 405) {
          setAuth(null);
          navigate("/");
          Cookies.remove("pnAccessToken");
          Cookies.remove("pnCmPk");
          Cookies.remove("userType");
          Cookies.remove("category");
          Cookies.remove("token_exp");
          getAccessToken("");
          setCaseManagerPk("");
          setSelectedProvider("");
          setSelectedClient("");
          setUserType("");
          setDashCompLimit(5);
          setErrorMessage("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderIcon = () => {
    if (open) {
      return <Close style={{ fontSize: "40px" }} />;
    }
    return <MenuIcon style={{ fontSize: "40px" }} />;
  };

  const renderMenuItem = () => {
    if (!Menus?.length || !userRoles.permissions) {
      return null;
    }

    const sortedMenus = [...Menus].sort((a, b) => a.order - b.order).map((Menu, index) => (
      <ListItem
        key={Menu.title}
        className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-black text-sm items-center gap-x-4  ${
          index === 0 && "bg-light-white"
        } `}
      >
        <Tooltip title={Menu.title} placement="right">
          <Link
            className="flex gap-6 items-center px-4 py-2 rounded-md hover:bg-indigo-500 hover:text-white w-full"
            to={`${Menu.url}`}
            onClick={() => setOpen(false)}
          >
            {Menu.icon}
            <span
              className={`${!open && "hidden"} pl-2 origin-left duration-200`}
            >
              {Menu.title}
            </span>
          </Link>
        </Tooltip>
      </ListItem>
    ));

    return sortedMenus;
  };

  const renderCloseIcon = () => {
    if (!open) {
      return null;
    }
    return <Close className="md:text-[40px] text-[30px] self-center" />;
  };

  const renderPatchInfo = () => {
    return (
      userRoles.permissions.includes("utilities.view_softwareupdate") && (
        <ListItem
          className={`flex rounded-md cursor-pointer hover:bg-light-white text-black text-sm items-center mt-2 relative p-0`}
        >
          <Tooltip title="Open Info" placement="right">
            <IconButton
              className={`flex items-center justify-start rounded-md mx-2 hover:bg-indigo-500 hover:text-white text-black relative pl-4 ${
                open ? "w-[204px]" : "w-[56px]"
              }`}
              onClick={() => setOpenPatchInfo(!openPatchInfo)}
            >
              <Info />
              <span
                className={`${
                  !open && "hidden"
                } pl-8 origin-left duration-200 text-[14px]`}
              >
                PATCH INFO
              </span>
            </IconButton>
          </Tooltip>
        </ListItem>
      )
    );
  };

  const renderUserMenu = () => {
    if (!userRoles.permissions.includes("users.view_user")) {
      return null;
    }

    return (
      <ListItem
        className={`${
          open ? "px-4 h-[50px]" : "p-0"
        } flex rounded-md cursor-pointer hover:bg-light-white text-black text-sm items-center justify-between`}
      >
        <PopupState variant="popover" popupId="user-menu-popup">
          {(popupState) => (
            <div
              className={`${
                open ? "w-full" : "w-fit"
              } flex items-center justify-stretch rounded-md cursor-pointer`}
            >
              <Button
                variant={open ? "contained" : ""}
                {...bindHover(popupState)}
                className={`${
                  open ? "w-[210px] justify-start pl-[22px]" : "justify-center"
                } capitalize bg-inherit flex items-center rounded-md text-black hover:bg-indigo-500 hover:text-white`}
                disableElevation={true}
              >
                <Group />
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-200 ml-7`}
                >
                  Users
                </span>
              </Button>
              <CustomPopUpMenu
                menuItems={generateUserMenuData()}
                popupState={popupState}
              />
            </div>
          )}
        </PopupState>
      </ListItem>
    );
  };

  const renderPatchInfoComponent = () => {
    if (!openPatchInfo) {
      return null;
    }

    return (
      <PatchInfo
        open={open}
        openPatchInfo={openPatchInfo}
        setOpenPatchInfo={setOpenPatchInfo}
      />
    );
  };

  const renderAvatar = () => {
    return loggedInUser?.profile_picture ? (
      <img
        src={loggedInUser?.profile_picture}
        alt="profile"
        className="w-full h-full"
      />
    ) : (
      <Person />
    );
  };

  return (
    <>
      <div className="fixed bg-white z-10 shadow-lg w-full sm:hidden flex items-center justify-between">
        <div className="w-1/3">
          <IconButton
            className="block pl-2 w-[100px] text-indigo-500"
            onClick={() => setOpen(!open)}
          >
            {renderIcon()}
          </IconButton>
        </div>
        <div className="w-1/3">&nbsp;</div>
      </div>
      <div className="bg-white flex fixed z-50 shadow-lg">
        <IconButton
          className="block pl-2 absolute m-4 z-10 text-indigo-500 sm:hidden"
          onClick={() => setOpen(!open)}
        >
          {renderCloseIcon()}
        </IconButton>
        <div
          className={`w-0 ${
            open ? "sm:w-64" : "sm:w-16"
          }  h-screen relative duration-300 bg-white`}
        >
          <div
            className={`sm:flex flex-col items-center h-full ${
              open
                ? "w-[100vw] sm:w-64 h-[100vh] bg-white overflow-y-auto border-r"
                : "hidden"
            }`}
          >
            <button
              className="sm:hidden block pl-4 absolute w-100 mt-4 left-0"
              onClick={() => setOpen(!open)}
            >
              X
            </button>
            <div className={`bg-white relative w-full flex ${open && "pl-6"}`}>
              <Button
                className={`hidden cursor-pointer relative sm:block 
              text-indigo-500 p-1 rounded-sm my-2 hover:bg-indigo-500 hover:text-white transition-all ${
                !open && "rotate-180"
              }`}
                onClick={() => setOpen(!open)}
              >
                <MenuOpenOutlined style={{ fontSize: "32px" }} />
              </Button>
            </div>
            <List className="p-0 bg-white sm:bg-white/[0] flex flex-col justify-center h-full">
              {renderMenuItem()}
              {renderPatchInfo()}
            </List>
            <List className="bg-white mt-auto w-full px-1">
              {renderUserMenu()}
              <ListItem
                className={`flex rounded-md cursor-pointer hover:bg-light-white text-black text-sm items-center w-full mt-auto px-0 py-2`}
              >
                <Tooltip title="My Account" placement="right">
                  <Link
                    className={`${
                      open ? "pl-4 w-[217px] mx-auto" : "pl-0 justify-center"
                    } flex rounded-md items-center w-full hover:bg-indigo-500 hover:text-white py-[6px]`}
                    to={`/my-account`}
                    onClick={() => setOpen(false)}
                  >
                    <div className="rounded-full overflow-hidden w-[35px] relative shadow-md flex justify-center">
                      {renderAvatar()}
                    </div>
                    <span
                      className={`${
                        !open && "hidden"
                      } pl-6 origin-left duration-200`}
                    >
                      My Account
                    </span>
                  </Link>
                </Tooltip>
              </ListItem>
              <ListItem
                className={`${
                  open
                    ? "w-[217px] mx-auto flex justify-start pl-[24px]"
                    : "justify-center"
                } cursor-pointer w-full bg-white rounded-md hover:bg-red-600 hover:text-white text-red-600 flex justify-start py-[6px]`}
                onClick={logout}
              >
                <LogoutOutlined />
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-200 ml-7`}
                >
                  LOGOUT
                </span>
              </ListItem>
            </List>
          </div>
        </div>
        {renderPatchInfoComponent()}
      </div>
    </>
  );
}
