import { List, ListItem, TextField } from "@mui/material";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { inputStyles } from "../cm/Dashboard/FullPages/constant";
import PropTypes from 'prop-types';

export default function PlacesResponseListV2({
  address,
  setAddress,
  handlePlaceSelect,
  inputRequired,
}) {
  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handlePlaceSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="w-full">
            <TextField
              required={inputRequired}
              className="w-full"
              sx={inputStyles}
              {...getInputProps({
                placeholder: "Search Address",
              })}
            />
            <List
              className="p-0 absolute z-10 bg-white w-full shadow-lg rounded-b-md max-h-[50vh] overflow-y-auto"
              style={{
                padding: "0",
                background: "white",
                width: "100%",
              }}
            >
              {loading && <div>...loading</div>}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#9333ea" : "#fff",
                  color: suggestion.active ? "#fff" : "#000",
                };
                return (
                  <ListItem
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestion.placeId}
                  >
                    {suggestion.description}
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

PlacesResponseListV2.propTypes = {
  address: PropTypes.string,
  setAddress: PropTypes.func,
  handlePlaceSelect: PropTypes.func,
  inputRequired: PropTypes.bool,
};
