import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import FollowUpViews from "./components/FollowUpViews";
import { PhoneOutlined, ArrowDropUp } from "@mui/icons-material";
import { useContext } from "react";
import DataContext from "../../../../../../../context/DataContext";
import { getDisplayFollowUp } from "./components/utils/getDisplayFollowUp";
import PropTypes from "prop-types";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { ACTION_TYPE } from "../types/actionTypes";

const { CLIENT_FOLLOW_UP, LAW_FIRM_FOLLOW_UP, PROVIDER_FOLLOW_UP } =
  ACTION_TYPE;

const SummaryComponent = ({
  displayFollowUp,
  caseInfo,
  isFollowUpsExpanded,
}) => {
  if (!displayFollowUp) {
    return (
      <FollowUpViews
        title={"Client Follow Ups"}
        icon={<PhoneOutlined />}
        actionType={CLIENT_FOLLOW_UP}
        caseInfo={caseInfo}
      />
    );
  }

  if (displayFollowUp?.action_type === CLIENT_FOLLOW_UP) {
    return (
      <FollowUpViews
        title={"Client Follow Ups"}
        icon={<PhoneOutlined />}
        actionType={CLIENT_FOLLOW_UP}
        caseInfo={caseInfo}
      />
    );
  }

  if (displayFollowUp?.action_type === LAW_FIRM_FOLLOW_UP) {
    return (
      <FollowUpViews
        title={"Law Firm Follow Ups"}
        icon={<PhoneOutlined />}
        actionType={LAW_FIRM_FOLLOW_UP}
        caseInfo={caseInfo}
      />
    );
  }

  if (displayFollowUp?.action_type === PROVIDER_FOLLOW_UP) {
    return (
      <FollowUpViews
        title={"Provider Follow Ups"}
        icon={<PhoneOutlined />}
        actionType={PROVIDER_FOLLOW_UP}
        caseInfo={caseInfo}
        isFollowUpsExpanded={isFollowUpsExpanded}
      />
    );
  }
};

SummaryComponent.propTypes = {
  displayFollowUp: PropTypes.object,
  caseInfo: PropTypes.object,
  isFollowUpsExpanded: PropTypes.bool,
};

const DetailsComponents = ({ displayFollowUp, caseInfo }) => {
  if (!displayFollowUp) {
    return (
      <div className="flex flex-col">
        <FollowUpViews
          title={"Law Firm Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={LAW_FIRM_FOLLOW_UP}
          caseInfo={caseInfo}
        />
        <FollowUpViews
          title={"Provider Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={PROVIDER_FOLLOW_UP}
          caseInfo={caseInfo}
        />
      </div>
    );
  }

  if (displayFollowUp?.action_type === CLIENT_FOLLOW_UP) {
    return (
      <div className="flex flex-col">
        <FollowUpViews
          title={"Law Firm Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={LAW_FIRM_FOLLOW_UP}
          caseInfo={caseInfo}
        />
        <FollowUpViews
          title={"Provider Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={PROVIDER_FOLLOW_UP}
          caseInfo={caseInfo}
        />
      </div>
    );
  }

  if (displayFollowUp?.action_type === LAW_FIRM_FOLLOW_UP) {
    return (
      <div className="flex flex-col">
        <FollowUpViews
          title={"Client Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={CLIENT_FOLLOW_UP}
          caseInfo={caseInfo}
        />
        <FollowUpViews
          title={"Provider Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={PROVIDER_FOLLOW_UP}
          caseInfo={caseInfo}
        />
      </div>
    );
  }

  if (displayFollowUp?.action_type === PROVIDER_FOLLOW_UP) {
    return (
      <div className="flex flex-col">
        <FollowUpViews
          title={"Client Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={CLIENT_FOLLOW_UP}
          caseInfo={caseInfo}
        />
        <FollowUpViews
          title={"Law Firm Follow Ups"}
          icon={<PhoneOutlined />}
          actionType={LAW_FIRM_FOLLOW_UP}
          caseInfo={caseInfo}
        />
      </div>
    );
  }

  return null;
};

DetailsComponents.propTypes = {
  displayFollowUp: PropTypes.object,
  caseInfo: PropTypes.object,
};

const CaseFollowUps = ({ clientId, caseId, lawFirmId }) => {
  const caseInfo = {
    clientId: clientId,
    caseId: caseId,
    lawFirmId: lawFirmId,
  };

  const {
    lawFirmFollowUps,
    clientFollowUps,
    providerFollowUps,
    isFollowUpsExpanded,
    setIsFollowUpsExpanded,
  } = useContext(DataContext);

  const displayFollowUp = getDisplayFollowUp([
    ...lawFirmFollowUps,
    ...clientFollowUps,
    ...providerFollowUps,
  ], isFollowUpsExpanded);

  return (
    <div id="Follow-Ups" data-testid="Follow-Ups">
      <Accordion expanded={isFollowUpsExpanded}>
        <AccordionSummary classes={{
          expanded: "!mb-0",
          content: "my-[20px]"
        }}>
          <div className="w-full">
            <button
              className="flex justify-between w-full"
              onClick={() => setIsFollowUpsExpanded(!isFollowUpsExpanded)}
            >
              <p className="font-bold pl-3">Follow Ups</p>
              <Tooltip title="Click to see all follow-ups">
                {isFollowUpsExpanded ? (
                  <ArrowDropDownIcon className="text-3xl" />
                ) : (
                  <ArrowDropUp className="text-3xl" />
                )}
              </Tooltip>
            </button>
            <SummaryComponent
              displayFollowUp={displayFollowUp}
              caseInfo={caseInfo}
              isFollowUpsExpanded={isFollowUpsExpanded}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails className="pt-0">
          <DetailsComponents
            displayFollowUp={displayFollowUp}
            caseInfo={caseInfo}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

CaseFollowUps.propTypes = {
  clientId: PropTypes.number,
  caseId: PropTypes.number,
  lawFirmId: PropTypes.number,
};

export default CaseFollowUps;
