import React from 'react';
import PropTypes from "prop-types";

const ProfileItem = ({title, description, desClsName}) => {
  return (
    <div className="flex">
      <p className="w-5/12">{title}</p>
      <p className={`w-7/12 ${desClsName}`}>{description}</p>
    </div>
  );
};

export default ProfileItem;

ProfileItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  desClsName: PropTypes.string,
};
