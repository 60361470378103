import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  ReportsDetailDispatchContext,
  ReportsDetailStateContext,
} from "../context/reportsDetailProvider";
import { CONTENT_DOWNLOAD_CONFIRMATION, setShowContent, toggleDialog, toggleDialogIsLoading } from "../state/actions";
import { ArrowDropDown, Download } from "@mui/icons-material";
import { ListItemIcon } from "@mui/material";

const ActionsButton = () => {
  const state = useContext(ReportsDetailStateContext);
  const dispatch = useContext(ReportsDetailDispatchContext);

  const handleDownloadReport = (popupState) => {
    popupState.close();
    setShowContent(dispatch, CONTENT_DOWNLOAD_CONFIRMATION);
    toggleDialogIsLoading(dispatch, false);
    toggleDialog(dispatch, true);
  };

  return (
    <div id="Actions-Button" data-testid="Actions-Button">
      <PopupState variant="popover" popupId="actions-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button variant="contained" {...bindTrigger(popupState)}>
              Actions
              <ArrowDropDown />
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleDownloadReport(popupState)}>
                <ListItemIcon>
                  <Download fontSize="small" />
                </ListItemIcon>
                Download Report
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </div>
  );
};

export default ActionsButton;
