import { TextField } from "@mui/material";
import { ReportsListStateContext } from "../context/reportsListProvider";
import { useContext } from "react";

export default function Search({ setSearchState }) {
  const state = useContext(ReportsListStateContext);

  return (
    <div>
      <div className="flex items-center">
        <TextField
          className="placeholder:text-center rounded-lg w-full text-sm mb-5"
          placeholder={"Search by report name, description"}
          size="small"
          name="reports-search"
          value={!state.search ? "" : state.search}
          InputLabelProps={{ shrink: true }}
          label="Search"
          onChange={(event) => {
            let searchVal = event.target.value;
            searchVal = searchVal.length === 0 ? "" : searchVal;
            setSearchState(searchVal);
          }}
        />
      </div>
    </div>
  );
}
