import PropTypes from 'prop-types';
import BaseField from '../../ui/BaseField';
import ValueWithFallback from '../../ui/ValueWithFallback';

const ProviderMax = ({ fieldName, value, flag, clock }) => {
  return (
    <BaseField
      id="SB-ProviderMax"
      testId="SB-ProviderMax"
      fieldName={fieldName}
      label={'Provider Max'}
      value={value}
      flag={flag}
      clock={clock}
    >
      <span>{ValueWithFallback(value)}</span>
    </BaseField>
  );
};

export default ProviderMax;

ProviderMax.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  flag: PropTypes.any,
  clock: PropTypes.any,
}
