import { useContext } from "react";
import { Button, Dialog } from "@mui/material";
import {
  CONTENT_ADD_FOLLOWUP,
  CONTENT_EDIT_FOLLOWUP,
  CONTENT_FOLLOWUPLIST,
  setDialogIsTouched,
  setfetchError,
  setfetchIsLoading,
  setfetchSuccess,
  setFieldData,
  setSelectedFollowUp,
  setShouldFetchData,
  setShowBackToFollowUpListButton,
  showContent,
  toggleDialog,
} from "../CaseFollowUps/state/actions";
import HistoryListView from "./components/modalcontent/HistoryListView";
import CreateFollowUp from "./components/modalcontent/CreateFollowUp";
import { Close } from "@mui/icons-material";
import LoadingIndicator from "../../../../../../global/LoadingIndicator";
import EditFollowUp from "./components/modalcontent/EditFollowUp";
import { case_detail } from "../../../../../../api/endpoints/case";
import DataContext from "../../../../../../../context/DataContext";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import { CONTENT_VIEW_FOLLOWUP } from "./components/modalcontent/HistoryListView/state/actions";
import ViewFollowUp from "./components/modalcontent/ViewFollowUp";
import { ButtonsConfig } from "../CaseFollowUps/components/utils/buttonsConfig";
import { STATUS_TYPES } from "../CaseFollowUps/components/types/statusTypes";
import Error from "./components/modalcontent/Error";
import STRINGS from "../CaseFollowUps/components/utils/strings";
import { ACTIONABLE_ITEM_TYPE_STRING } from "../types/actionTypes";
import PropTypes from "prop-types";

const FollowUpModal = ({ actionType, state, dispatch }) => {
  const { accessToken } = useContext(DataContext);

  const handleClose = () => {
    toggleDialog(dispatch, false);
    setfetchError(dispatch, false);
    setShowBackToFollowUpListButton(dispatch, false);
    showContent(dispatch, null);
    if (!state.showDialog) {
      setSelectedFollowUp(dispatch, null);
    }
  };

  const createFollowUpRecord = (newFollowUp) => {
    case_detail
      .createFollowUp(newFollowUp, accessToken)
      .then((res) => {
        setfetchIsLoading(dispatch, true);
        setShouldFetchData(dispatch, true);
        return res;
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        handleClose();
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.toString());
        setfetchError(dispatch, { showError: true, errorMessage: errArr });
        setDialogIsTouched(dispatch, false);
        setfetchIsLoading(dispatch, false);
      });
  };

  const editFollowUpRecord = (followUpId, newFollowUp) => {
    case_detail
      .updateFollowUp(followUpId, newFollowUp, accessToken)
      .then((res) => {
        setfetchIsLoading(dispatch, true);
        setShouldFetchData(dispatch, true);
        return res;
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        return res;
      })
      .then((res) => {
        handleClose();
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.toString());
        setfetchError(dispatch, { showError: true, errorMessage: errArr });
        setDialogIsTouched(dispatch, false);
        setfetchIsLoading(dispatch, false);
      });
  };

  const markCompleteFollowUpRecord = (followUpId) => {
    editFollowUpRecord(followUpId, { status: STATUS_TYPES.DONE });
    setFieldData(dispatch, {
      fieldName: actionType,
      data: null,
    });
    setShouldFetchData(dispatch, true);
  };

  const handleSubmitForm = (submitType, formData) => {
    if (submitType === ButtonsConfig.SubmitButtons.create.submitType) {
      createFollowUpRecord(formData);
    } else if (submitType === ButtonsConfig.SubmitButtons.update.submitType) {
      editFollowUpRecord(state.selectedFollowUp.id, formData);
    } else if (
      submitType === ButtonsConfig.SubmitButtons.markComplete.submitType
    ) {
      markCompleteFollowUpRecord(state.selectedFollowUp.id);
    } else {
      let errArr = ApiRequestErrorHandler("Error Submitting Form");
      setfetchError(dispatch, errArr);
    }
  };

  const setUpDialogContent = (content) => {
    return getDialogContentUI(content);
  };

  const listenForUserInteraction = (value) => {
    setDialogIsTouched(dispatch, value);
  };

  const navigateToDetail = (caseId) => {
    window.open(`/client-detail/${caseId}`, "_blank");
  };

  const getDialogContentUI = (content) => {
    switch (content) {
      case CONTENT_ADD_FOLLOWUP: {
        return (
          <CreateFollowUp
            onClickSubmit={handleSubmitForm}
            title={ACTIONABLE_ITEM_TYPE_STRING[actionType]}
            caseInfo={state.caseInfo}
            actionType={actionType}
            formIsTouched={listenForUserInteraction}
            navigateToDetail={navigateToDetail}
          />
        );
      }
      case CONTENT_EDIT_FOLLOWUP: {
        return (
          <EditFollowUp
            onClickSubmit={handleSubmitForm}
            title={
              ACTIONABLE_ITEM_TYPE_STRING[state.selectedFollowUp.actionType]
            }
            selectedFollowUp={state.selectedFollowUp}
            actionType={actionType}
            caseInfo={state.caseInfo}
            formIsTouched={listenForUserInteraction}
            navigateToDetail={navigateToDetail}
          />
        );
      }
      case CONTENT_FOLLOWUPLIST: {
        return <HistoryListView caseInfo={state.caseInfo} />;
      }
      case CONTENT_VIEW_FOLLOWUP: {
        return (
          <ViewFollowUp
            title={
              ACTIONABLE_ITEM_TYPE_STRING[state.selectedFollowUp.actionType]
            }
            selectedFollowUp={state.selectedFollowUp}
            actionType={actionType}
            caseInfo={state.caseInfo}
            navigateToDetail={navigateToDetail}
          />
        );
      }
      default: {
        return <LoadingIndicator isLoading={true} />;
      }
    }
  };
  return (
    <Dialog open={state.showDialog} fullWidth maxWidth="lg">
      <div className="outer text-center p-2">
        <div className="text-right">
          <Button onClick={(e) => handleClose(e)}>
            <Close />
          </Button>
        </div>
        <div className="text-left">
          {state.prevContent === CONTENT_FOLLOWUPLIST && (
            <Button onClick={() => showContent(dispatch, CONTENT_FOLLOWUPLIST)}>
              Go Back to {STRINGS.FOLLOW_UP_LIST_TITLE}
            </Button>
          )}
        </div>
        <div className="text-center p-2">
          {state.dialogIsLoading ? (
            <LoadingIndicator isLoading={true} />
          ) : (
            <div
              className="text-center p-2"
              data-testid="FollowUp-Dialog-Content"
            >
              {setUpDialogContent(state.showContent)}
            </div>
          )}
          {state.error && !state.dialogIsTouched && (
            <Error error={state.errorArray} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

FollowUpModal.propTypes = {
  actionType: PropTypes.string,
  state: PropTypes.object,
  dispatch: PropTypes.func,
};

export default FollowUpModal;
