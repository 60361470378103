import React, { useContext } from "react";
import Name from "../fields/Name";
import Column from "../ui/Column";

import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import { StaticBarStateContext } from "../../context/StaticBarContext";
import { BirthDate } from "../fields/BirthDate/BirthDate";
import CommunicationStatus from "../fields/CommunicationStatus";
import DoNotContact from "../fields/DoNotContact";
import PhoneNumber from "../fields/PhoneNumber";
import PreferredLanguage from "../fields/PreferredLanguage";
import RelatedCases from "../fields/RelatedCases";

const ClientInfoSummary = () => {
  const state = useContext(StaticBarStateContext);

  const ageFlagState = state.data?.age?.flag_state;
  const hasAgeRelatedCases = state.data?.related_cases?.value?.length > 0
  return (
    <Column>
      {state?.data?.name && (
        <PermissionWrapper permission={state?.data?.name?.can_view}>
          <Name
            fieldName={state.data?.name?.fieldName}
            prefix={state.data?.name_prefix?.value}
            name={state.data?.name?.value}
            flag={state.data?.name?.flag_state}
            clock={state.data?.name?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.date_of_birth && state.data?.age && (
        <PermissionWrapper permission={state.data?.date_of_birth?.can_view}>
          <BirthDate
            fieldName={state.data?.date_of_birth?.fieldName}
            dateOfBirth={state.data?.date_of_birth?.value}
            age={state.data?.age?.value}
            minorCheck={state.data?.age?.flag_state}
            needsValidation={state.ui?.age?.needsValidation}
            flag={state.data?.age?.flag_state}
            clock={state.data?.age?.clock_state}
          />
        </PermissionWrapper>
      )}
      {state.data?.preferred_language && (
        <PermissionWrapper
          permission={state.data?.preferred_language?.can_view}
        >
          <PreferredLanguage
            fieldName={state.data?.preferred_language?.fieldName}
            value={state.data?.preferred_language?.value}
            flag={state.data?.preferred_language?.flag_state}
            clock={state.data?.preferred_language?.clock_state}
          />
        </PermissionWrapper>
      )}
      <div className='flex h-[32px]'>
      {state.data?.phone && (
        <PermissionWrapper permission={state.data?.phone?.can_view}>
          <PhoneNumber
            fieldName={state.data?.phone?.fieldName}
            number={state.data?.phone?.value}
            flag={state.data?.phone?.flag_state}
            clock={state.data?.phone?.clock_state}
          />
        </PermissionWrapper>
      )}
        <div className="flex space-x-2 p-0"
        style={{marginTop:ageFlagState > 1 || hasAgeRelatedCases ? "-10px" : "0" }}>
          {state.data?.related_cases &&
            hasAgeRelatedCases && (
              <PermissionWrapper permission={state.data?.related_cases?.can_view}>
                <RelatedCases relatedCases={state.data?.related_cases?.value} />
              </PermissionWrapper>
            )}
          {!ageFlagState && (
            <PermissionWrapper permission={state.data?.age?.can_view}>
              <DoNotContact minorCheck={ageFlagState} />
            </PermissionWrapper>
          )}
        </div>
      </div>
      {
        state.data?.comm_status &&(
          <PermissionWrapper permission={state.data?.comm_status?.can_view}>
            <CommunicationStatus
                        fieldName={state.data?.comm_status?.fieldName}
                        value={state.data?.comm_status?.value}
                        flag={state.data?.comm_status?.flag_state}
                        clock={state.data?.comm_status?.clock_state}
            />
          </PermissionWrapper>
        )
      }
    </Column>
  );
};

export default ClientInfoSummary;
