import { DriveFolderUpload } from "@mui/icons-material";
import PropTypes from 'prop-types';
import PermissionWrapper from "../../../../../../../global/PermissionWrapper";
import ActionButton from "../ui/ActionButton";

const UploadDocument = ({
  permission,
  docNeedsType,
  setUploadDocument,
  setViewDocuments,
}) => {
  const openUploadModal = () => {
    if (!docNeedsType || docNeedsType.docs_with_no_type === 0) {
      setUploadDocument(true);
    } else if (docNeedsType.docs_with_no_type > 0) {
      setUploadDocument(true);
      setViewDocuments(true);
    }
  };

  return (
    <PermissionWrapper permission={permission}>
      <ActionButton
        title={"Upload Document to Case"}
        icon={<DriveFolderUpload fontSize='18'/>}
        action={openUploadModal}
      />
    </PermissionWrapper>
  );
};

export default UploadDocument;

UploadDocument.propTypes = {
  permission: PropTypes.any,
  docNeedsType: PropTypes.any,
  setUploadDocument: PropTypes.func,
  setViewDocuments: PropTypes.func,
}
