import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";
import React from "react";
import AutoCompleteSearch from "../AutoCompleteSearch/AutoCompleteSearchV2";
import MultiSelectAutoComplete from "../AutoCompleteSearch/MultiSelectAutoCompleteV2";
import SmartyAutoComplete from "../SmartyAutoComplete";
import ShowMoreButton from "../ShowMoreButton";

export default function DynamicInput({
  type,
  setVal,
  val,
  title,
  options,
  keyVal,
  displayNameKey,
  caseDetail,
  apiUrl,
  activeEditObj,
  responseSuccess,
  customLimit,
  postKeyVal,
  optionsDisplayKey,
  nullable,
  showMoreVisible,
  setResLimit,
  resLimit,
}) {
  if (type === "text") {
    return (
      <TextField
        className="mt-2 w-full"
        size="small"
        label={title}
        onChange={(e) => setVal(e.target.value)}
        value={val}
        error={responseSuccess === false ? true : false}
      />
    );
  } else if (type === "number") {
    return (
      <TextField
        size="small"
        className="mt-2 w-full"
        type="number"
        label={title}
        onChange={(e) => setVal(e.target.value)}
        value={val}
        error={responseSuccess === false ? true : false}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    );
  } else if (type === "boolean") {
    return (
      <FormControl className="flex-row items-center">
        <label className="my-1 font-bold mr-2">{title}: </label>
        <RadioGroup
          title={title}
          className="text-sm flex-row"
          name="radio-buttons-group"
          onChange={(e) => setVal(e.target.value)}
          value={val}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={"True"}
            className="text-sm"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={"False"}
            className="text-sm"
          />
        </RadioGroup>
      </FormControl>
    );
  } else if (type === "select") {
    return (
      <FormGroup className="w-full">
        <InputLabel id={keyVal} className="text-sm">
          {title}
        </InputLabel>
        <Select
          size="small"
          label={title}
          onChange={(e) => setVal(e.target.value)}
          className="text-sm w-full"
          value={val}
          error={responseSuccess === false ? true : false}
          MenuProps={{
            sx: { height: "300px", maxWidth: "100%" },
          }}
        >
          <MenuItem
            value="No Selection"
            label="No Selection"
            className="text-sm"
            disabled={nullable ? false : true}
          >
            No Selection
          </MenuItem>
          {!options
            ? ""
            : options.map((item) => (
                <MenuItem
                  value={item.key}
                  key={item.key}
                  label={item.label}
                  className="text-sm"
                >
                  {item.label}
                </MenuItem>
              ))}
          <ShowMoreButton
            showMoreVisible={showMoreVisible}
            setResLimit={setResLimit}
            resLimit={resLimit}
            showMoreAmt={10}
          />
        </Select>
      </FormGroup>
    );
  } else if (type === "date") {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          className="w-full text-center my-1"
          label={title}
          value={!caseDetail[keyVal] ? new Date() : parseISO(val)}
          onChange={(newValue) =>
            setVal(format(new Date(newValue), "yyyy-MM-dd"))
          }
          slotProps={{
            textField: {
              variant: "outlined",
              error: responseSuccess === false ? true : false,
            },
          }}
        />
      </LocalizationProvider>
    );
  } else if (type === "search-select") {
    return (
      <div className="w-full text-center my-1">
        <AutoCompleteSearch
          apiUrl={apiUrl}
          keyVal={keyVal}
          caseDetail={caseDetail}
          title={title}
          setVal={setVal}
          activeEditObj={activeEditObj}
          displayKey={displayNameKey}
          customLimit={customLimit}
          postKeyVal={postKeyVal}
          optionsDisplayKey={optionsDisplayKey}
          error={responseSuccess === false ? true : false}
        />
      </div>
    );
  } else if (type === "multi-search-select") {
    return (
      <div className="w-full text-center my-1">
        <MultiSelectAutoComplete
          apiUrl={apiUrl}
          keyVal={keyVal}
          caseDetail={caseDetail}
          title={title}
          setVal={setVal}
          activeEditObj={activeEditObj}
          displayKey={displayNameKey}
          customLimit={customLimit}
          postKeyVal={postKeyVal}
          optionsDisplayKey={optionsDisplayKey}
          error={responseSuccess === false ? true : false}
        />
      </div>
    );
  } else if (type === "address") {
    return (
      <div className="w-full text-center my-1">
        <SmartyAutoComplete address={val} setAddress={setVal} />
      </div>
    );
  } else {
    return;
  }
}
