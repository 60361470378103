import { Button, ListItem } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../context/DataContext";
import propType from "prop-types"
import { ENTITY_TYPE_LAW_FIRM, ENTITY_TYPE_PROVIDER } from "../../globalConstants";

export default function ClientListItemV2({
  client,
  index,
  currentPage,
  columnClasses,
  columnClassesLg,
  columnClassesSmall
}) {
  const { loggedInUser } = useContext(DataContext);
  const { setSelectedClient } = useContext(DataContext);

  const isProvider = loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_PROVIDER;
  const isLawFirm = loggedInUser?.entity?.entity_type_label === ENTITY_TYPE_LAW_FIRM;

  const navigate = useNavigate();
  const resultClasses =
    "text-sm border-gray-100 sm:border-r sm:last-of-type:border-0 sm:p-2 px-2 py-2 sm:border-b-0";

  const renderNumber = (number) => {
    return `$${number ? number.toLocaleString() : "0.00"}`
  }

  const navigateToDetail = (client) => {
    setSelectedClient(client.pk);
    navigate(`/client-detail/${client.pk}`);
  };

  return (
    <ListItem
      key={client.pk}
      className={`py-0 pt-0 items-start`}
    >
      <Button
        onClick={() => navigateToDetail(client)}
        className="text-black shadow-lg py-1 px-0 my-1 w-full flex bg-white rounded-md border-gray-100 border-y hover:bg-purple-600 hover:text-white justify-between"
      >
        <span
          className={`block ${columnClassesLg} pl-2 text-left break-words ${resultClasses}`}
        >
          {currentPage > 1
            ? index + 1 * ((currentPage - 1) * 25) + 1
            : index + 1}
          .&nbsp;
          {client?.client?.name || ""}
        </span>
        <span
          className={`block ${columnClassesSmall} pl-2 text-center ${resultClasses}`}
        >
          {!client?.date_of_accident
            ? ""
            : new Date(client.date_of_accident).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
        </span>
        <span
          className={`block ${columnClassesSmall} pl-2 text-center break-words ${resultClasses}`}
        >
          {client?.status || ""}
        </span>
        <span
          className={`block ${columnClassesSmall} text-center break-words ${resultClasses}`}
        >
          {client?.overall_status || ""}
        </span>
        {!isLawFirm && (
          <span
            className={`block ${columnClasses} text-center break-words ${resultClasses}`}
          >
            {client?.law_firm_name || ""}
          </span>
        )}
        {!isProvider &&
          <span
            className={`block ${columnClassesSmall} text-center ${resultClasses}`}
          >
            {renderNumber(client?.medical_limits)}
          </span>
        }
        <span
          className={`block ${columnClassesSmall} text-center ${resultClasses}`}
        >
          {renderNumber(client?.current_balance_total)}
        </span>
        <span
          className={`block ${columnClassesSmall} text-center ${resultClasses}`}
        >
          {renderNumber(client?.running_total)}
        </span>
      </Button>
    </ListItem>
  );
}

ClientListItemV2.propTypes = {
  client: propType.object,
  index: propType.number,
  currentPage: propType.number,
  columnClasses: propType.string,
  columnClassesLg: propType.string,
  columnClassesSmall: propType.string
}