import { Close, ControlPointOutlined } from "@mui/icons-material";
import {
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../../../../../../context/DataContext";
import axios from "../../../../../../api/axios";
import ResponseModal from "../../../../../../global/ResponseModal";
import ApiRequestErrorHandler from "../../../../../../global/ApiRequestErrorHandler";
import Modal from "../../../../../../global/Modal";
import FormField from "../../../../../../global/FormComponents/field/FormField";
import { inputStyles, selectStyles } from "../../../constant";

export default function AddExpense({
  casePk,
  addOpen,
  setAddOpen,
  trigger,
  setTrigger,
  activeExpense,
  setActiveExpense,
}) {
  const { accessToken } = useContext(DataContext);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [deductionOptions, setDeductionOptions] = useState([]);
  const [deductionType, setDeductionType] = useState(3);
  const [label, setLabel] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpenSuccess = () => {
    setAddOpen(false);
    setOpenSuccess(!openSuccess);
    setTrigger(!trigger);
    setLabel("");
    setExpenseAmount("");
  };

  useEffect(() => {
    if (!activeExpense) {
      return;
    } else {
      setExpenseAmount(activeExpense.amount);
      setLabel(activeExpense.label);
      setDeductionType(activeExpense.deduction_type);
    }
  }, [addOpen, activeExpense]);

  useEffect(() => {
    axios
      .options("/api/additional_expense/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        setDeductionOptions(response.data.actions.POST.deduction_type.choices);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setDeductionOptions, accessToken]);

  const handleExpenseUpdate = () => {
    setLoading(true);
    const postData = {
      label: label,
      amount: +expenseAmount,
      deduction_type: +deductionType,
    };

    axios
      .patch(`/api/additional_expense/${activeExpense.pk}/`, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setOpenSuccess(!openSuccess);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setOpenError(!openError);
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
        }
      });
  };

  const handleExpenseSubmit = () => {
    setLoading(true);
    const postData = {
      label: label,
      case: +casePk,
      amount: +expenseAmount,
      deduction_type: +deductionType,
    };

    axios
      .post("/api/additional_expense/", postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then(() => {
        setOpenSuccess(!openSuccess);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setOpenError(!openError);
          const res = error.response.data;
          const errArr = ApiRequestErrorHandler(res);
          setErrorArray(errArr);
        }
      });
  };

  const handleClose = () => {
    setAddOpen(false);
    setActiveExpense("");
    setExpenseAmount("");
    setLabel("");
    setDeductionType("");
  };

  return (
    <Modal
      open={addOpen}
      title={!activeExpense ? "Add Expense" : "Edit Expense"}
      maxWidth="xs"
      onClose={handleClose}
      actions={[
        {
          isLoading: loading,
          onClick: !activeExpense ? handleExpenseSubmit : handleExpenseUpdate,
          label: "Submit Expense",
        },
      ]}
    >
      <div className="flex flex-col gap-4">
        <FormField label="Deduction Type">
          <Select
            required
            className="w-full"
            value={deductionType}
            sx={selectStyles}
            onChange={(event) => {
              setDeductionType(event.target.value);
            }}
          >
            {deductionOptions.map((type, idx) => {
              return (
                <MenuItem
                  value={type.value}
                  key={idx}
                  onClick={() => setDeductionType(type.value)}
                >
                  {type.display_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormField>
        <FormField label="Expense Label">
          <TextField
            value={label}
            sx={inputStyles}
            type="text"
            onChange={(e) => setLabel(e.target.value)}
          />
        </FormField>
        <FormField label="Expense Amount">
          <TextField
            value={expenseAmount}
            type="number"
            sx={inputStyles}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => setExpenseAmount(e.target.value)}
          />
        </FormField>
      </div>
      <ResponseModal
        title={`Expense Addition Successful`}
        description="Case expense has been added"
        openBool={openSuccess}
        setOpenBool={setOpenSuccess}
        handleCloseFunc={handleOpenSuccess}
      />
      <ResponseModal
        title={`Expense Add Failed`}
        isError={true}
        openBool={openError}
        setOpenBool={setOpenError}
        errorMessage={errorArray}
      />
    </Modal>
  );
}
