import React from 'react';
import PropTypes from 'prop-types';
import ProfileItem from '../ProfileItem/ProfileItem';

const GeneralUserInfo = ({ userInfo, languageObj }) => {
  let preferLanguage;
  if (!userInfo?.preferred_language) {
    preferLanguage = 'Unknown';
  } else if (!languageObj) {
    preferLanguage = '';
  } else {
    preferLanguage = languageObj[userInfo?.preferred_language].description;
  }

  const spokenLanguage =
    !userInfo?.spoken_language || userInfo?.spoken_language.length === 0
      ? ''
      : userInfo?.spoken_language.map((lang, idx) => (
          <p key={idx + lang} className="capitalize">
            {!languageObj ? '' : languageObj[lang].subtag}
          </p>
        ));

  return (
    <div className="relative mb-1 border-t border-gray">
      <div className="flex justify-between items-center h-[40px]">
        <h3 className="font-bold text-[18px]">General User Info</h3>
      </div>
      <div className="grid grid-cols-2 gap-3 mb-2">
        <ProfileItem title="Name" description={userInfo?.name} />
        <ProfileItem
          title="Email"
          desClsName="break-all"
          description={userInfo?.email}
        />
        <ProfileItem title="Username" description={userInfo?.username} />
        <ProfileItem title="Phone" description={userInfo?.phone} />
        <ProfileItem
          title="Date of Birth"
          description={userInfo?.date_of_birth}
        />
        <ProfileItem
          title="Address"
          description={userInfo?.address?.formatted || userInfo?.address?.raw}
        />
        <ProfileItem title="Preferred Language" description={preferLanguage} />
        <ProfileItem title="Spoken Languages" description={spokenLanguage} />
      </div>
    </div>
  );
};

export default GeneralUserInfo;

GeneralUserInfo.propTypes = {
  userInfo: PropTypes.object.isRequired,
  languageObj: PropTypes.object.isRequired
};
