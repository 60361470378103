export const MEDICAL_RECORD_NUMBER_KEY_VAL = "medical_record_number";

export const TOTAL_INPUTS = [
  {
    title: "Current Balance",
    keyVal: "current_balance",
    type: "number",
    noDataPlaceholder: "Add Amount",
    tooltipEnabled: true,
  },
  {
    title: "Medical Record Number",
    keyVal: MEDICAL_RECORD_NUMBER_KEY_VAL,
    type: "text",
    noDataPlaceholder: "Add Number"
  }
];
