import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  ReportsDetailDispatchContext,
  ReportsDetailStateContext,
} from "../context/reportsDetailProvider";
import { setOrderingBy, setOrderingDirection } from "../state/actions";
import ClientType from "./columnTypes/ClientType";
import CurrencyType from "./columnTypes/CurrencyType";
import DateType from "./columnTypes/DateType";
import Colors from "../../../../../../../global/Colors";
import ProviderType from "./columnTypes/ProviderType";
import { visuallyHidden } from "@mui/utils";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const ReportTable = () => {
  const state = useContext(ReportsDetailStateContext);
  const dispatch = useContext(ReportsDetailDispatchContext);

  const handleRequestSort = (event, property) => {
    const isAsc =
      state.orderingBy === property && state.orderingDirection === "asc";
    setOrderingDirection(dispatch, isAsc ? "desc" : "asc");
    setOrderingBy(dispatch, property);
  };
  const formatColumns = (columnType, value) => {
    switch (columnType) {
      case "ClientColumn":
        return <ClientType clientObj={value} />;
      case "DateTimeColumn":
        return <DateType date={value.value} newLine={true} />;
      case "DecimalColumn":
        return <CurrencyType amount={value.value} />;
      case "ProviderColumn":
        return (
          <ProviderType providerId={value.id} providerName={value.value} />
        );
      default:
        return value.value;
    }
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {state.columns &&
            Object.keys(state.columns).length > 0 &&
            Object.keys(state.columns).map((col) => (
              <TableCell
                key={state.columns[col].field_name}
                sortDirection={
                  state.orderingBy === state.columns[col].field_name
                    ? state.orderingDirection
                    : false
                }
              >
                <TableSortLabel
                  active={state.orderingBy === state.columns[col].field_name}
                  direction={
                    state.orderingBy === state.columns[col].field_name
                      ? state.orderingDirection
                      : "asc"
                  }
                  onClick={createSortHandler(state.columns[col].field_name)}
                  sx={{
                    fontWeight: "bold",
                    lineHeight: "1.25rem",
                    ".MuiTableSortLabel-icon": {
                      opacity: 1,
                      color:state.orderingBy === state.columns[col].field_name
                      ? Colors.purple[500]
                      : Colors.slate[500]
                    },
                  }}
                  IconComponent={ArrowDropDownIcon}
                >
                  {state.columns[col].display}
                  {state.orderingBy === state.columns[col].field_name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {state.orderingDirection === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };
  const createColumns = (rowIndex) => {
    return state.columns.map((cell) => {
      let fieldName = cell.field_name;
      return (
        <TableCell
          key={fieldName + "_" + rowIndex}
          sx={{ border: 1, borderColor: Colors.slate[300] }}
        >
          <div>{formatColumns(cell.type, state.data[rowIndex][fieldName])}</div>
        </TableCell>
      );
    });
  };

  return (
    <div id="Report-Table" data-id="Report-Table">
      <TableContainer className="overflow-auto max-h-[50vh]">
        <Table stickyHeader size="small">
          <TableHeader
            order={state.orderingDirection}
            orderBy={state.orderingBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {state.data &&
              state.data.map((rows, idx) => {
                return (
                  <TableRow key={"rows_" + idx}>{createColumns(idx)}</TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportTable;
