import { WatchLater } from "@mui/icons-material";
import PropTypes from 'prop-types';
import { getColor } from "../../cm/Dashboard/FullPages/ClientDetail/StaticBar/components/ui/getColor";
import ClockTooltip from "./ClockTooltip";

const Clock = ({
  fieldName,
  clockValue,
  tooltipTitle,
  tooltipDesc,
  tooltipObj,
  size,
}) => {
  const getClockColor = (value) => {
    return getColor(value);
  };
  const getSize = (value) => {
    if (
      !value ||
      value === null ||
      value === undefined 
    ) {
      return "0.625";
    }
    return value + "rem";
  };
  return (
    <div id={"Clock_" + fieldName} data-testid={"Clock_" + fieldName}>
      <ClockTooltip
        fieldName={fieldName}
        title={tooltipTitle}
        desc={tooltipDesc}
        legend={tooltipObj}
      >
        <div
          className="ml-3 flex"
          style={{ fontSize: getSize(size), color: getClockColor(clockValue) }}
        >
          <WatchLater fontSize="inherit" />
        </div>
      </ClockTooltip>
    </div>
  );
};

export default Clock;

Clock.propTypes = {
  fieldName: PropTypes.any,
  clockValue: PropTypes.any,
  tooltipTitle: PropTypes.any,
  tooltipDesc: PropTypes.any,
  tooltipObj: PropTypes.any,
  size: PropTypes.any,
}
